import React, { useEffect, useState, useRef } from 'react';
import { Slide, useBreakpointValue } from '@chakra-ui/react';

import { Box, Flex } from '@allergan-data-labs/alle-elements-layout';

import { AuthState } from '../../../auth/authMachine';
import { useAuth } from '../../../auth/AuthProvider';
import { useCmsPreview } from '../../../hooks/useCmsPreview';
import { HeaderMenu } from './headerMenu';
import { HeaderLogo } from './headerLogo';
import { HeaderNav } from './nav/headerNav';
import { HeaderAccount } from './account/headerAccount';
import { HeaderDrawer } from './drawer/headerDrawer';
import { useHeaderColors } from '../helpers/useHeaderColors';
import { testIds } from '../_testIds';

export type HeaderProps = {
  keepPathOnLogin?: boolean;
  relative?: boolean;
};

const Header = ({ keepPathOnLogin, relative }: HeaderProps) => {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const prevScrollTop = useRef(0);

  const { currentUser, authState } = useAuth();
  const { cmsLoggedInPreview } = useCmsPreview();

  const isLoggedIn =
    cmsLoggedInPreview ??
    (!!currentUser || authState.matches(AuthState.isLoggedIn));

  const isAboveTablet = useBreakpointValue(
    { base: false, md: true },
    { ssr: false }
  );

  const { bgColor, borderColor } = useHeaderColors();

  useEffect(() => {
    if (isAboveTablet) setIsNavDrawerOpen(false);
  }, [isAboveTablet]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollingUp = window.scrollY < prevScrollTop.current;
      if (scrollingUp) setIsHeaderHidden(false);
      else setIsHeaderHidden(window.scrollY > 100);
      prevScrollTop.current = window.scrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box data-testid={testIds.header}>
      {!relative ? <Box width="full" height={64} /> : null}
      <Slide
        direction="top"
        in={relative ? true : !isHeaderHidden}
        style={{ zIndex: 10, position: relative ? 'relative' : 'fixed' }}
        motionProps={{ initial: false }}
      >
        <Flex
          data-testid={testIds.headerInner}
          padding={{ base: '12px 16px 12px 8px', md: '12px 16px' }}
          justifyContent="space-between"
          alignItems="center"
          height={64}
          boxSizing="border-box"
          borderBottom={`1px solid`}
          borderColor={borderColor}
          background={bgColor}
        >
          <Flex gap={8} alignItems="center">
            {!isAboveTablet ? (
              <HeaderMenu
                isOpen={isNavDrawerOpen}
                onClick={() => setIsNavDrawerOpen(!isNavDrawerOpen)}
              />
            ) : null}
            <Flex alignItems="center" gap={24}>
              <HeaderLogo />
              {isAboveTablet ? (
                <HeaderNav
                  isLoggedIn={isLoggedIn}
                  isHeaderHidden={isHeaderHidden}
                />
              ) : null}
            </Flex>
          </Flex>
          {isLoggedIn || !isNavDrawerOpen ? (
            <HeaderAccount
              isLoggedIn={isLoggedIn}
              keepPathOnLogin={keepPathOnLogin}
            />
          ) : null}
        </Flex>
      </Slide>
      <HeaderDrawer
        isOpen={isNavDrawerOpen}
        isLoggedIn={isLoggedIn}
        keepPathOnLogin={keepPathOnLogin}
        relative={relative}
        toggleDrawer={() => setIsNavDrawerOpen(!isNavDrawerOpen)}
      />
    </Box>
  );
};

export { Header };
