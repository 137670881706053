import { BehaviorSubject } from 'rxjs';
import { createHeader } from '../createHeader';
import {
  getAnonymousId,
  getAnonymousIdFromAnalytics,
} from '../anonymous-id/anonymousId';

const ADLSIDHEADERKEY = 'ADL-SID';
const ADLSIDSUFFIX = 'app:consumer-web';

/**
 * @template T - The type of the value emitted by the BehaviorSubject.
 * @param {T} [defaultValue] - The initial value emitted by the BehaviorSubject. If not provided, the initial value will be `undefined`.
 * @returns {BehaviorSubject<T | undefined>} A new BehaviorSubject instance.
 */
export const createUserAdlSidSubject = <T>(defaultValue?: T) =>
  new BehaviorSubject<T | undefined>(defaultValue);

/**
 * @typeParam T - The type of the SID, which can be a string, null, or undefined.
 */
const userAdlSidSubject = createUserAdlSidSubject<string | null | undefined>(
  undefined
);

/**
 * @param userId - The user ID to set. It can be a string, null, or undefined. Defaults to undefined.
 * @returns A function that clears the ADL SID.
 */
export const setUserAdlSid = (
  userId: string | null | undefined = undefined
): (() => void) => {
  userAdlSidSubject.next(userId);
  return () => clearAdlSid();
};

export const getuserAdlSid = (): string | undefined => {
  const value = userAdlSidSubject.getValue();
  return value !== null ? value : undefined;
};

export const getAnonAdlSid = () => {
  return getAnonymousId(getAnonymousIdFromAnalytics);
};

export const clearAdlSid = () => {
  userAdlSidSubject.next(undefined);
};

export const unsubscribeUserAdlSid = () => {
  userAdlSidSubject.unsubscribe();
};

/**
 * @param userId - The user ID, if available.
 * @param anonymousId - The anonymous ID, if the user ID is not available.
 * @returns The composed ADL SID header value.
 */
const composeAdlSidHeader = (
  userId: string | undefined,
  anonymousId: string | undefined
) => {
  const ADLSIDPREFIX = userId ? 'user' : 'anon';
  const ID = userId ? userId : anonymousId;
  const ADLSID = `${ADLSIDPREFIX}:${ID}#${ADLSIDSUFFIX}`;
  return createHeader(ADLSIDHEADERKEY, ADLSID);
};

/**
 * @returns The ADL SID header value.
 */
export const getAdlSidHeader = (): { [x: string]: string } => {
  return composeAdlSidHeader(getuserAdlSid(), getAnonAdlSid());
};
