import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useColorMode } from '@allergan-data-labs/alle-elements-core';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';

import { useHeaderTrackingEvents } from '../../helpers/useHeaderTrackingEvents';
import { HeaderLink } from '../../types';
import { testIds } from '../../_testIds';

export type HeaderDrawerNavLinkProps = {
  link: HeaderLink;
  onLinkClick: () => void;
};

const HeaderDrawerNavLink = ({
  link,
  onLinkClick,
}: HeaderDrawerNavLinkProps) => {
  const history = useHistory();
  const currentPath = history.location.pathname;

  const isActive = useRef(currentPath === link.href);

  const { Link } = useEnvironmentContext();
  const { colorMode } = useColorMode();
  const { trackHeaderLinkClick } = useHeaderTrackingEvents();

  // ensures the link style does not change until the menu is closed
  // avoids causing visual jumps
  useEffect(() => {
    setTimeout(() => {
      isActive.current = currentPath === link.href;
    }, 150);
  }, [currentPath, link.href]);

  return (
    <Link
      key={link.id}
      data-testid={`${testIds.headerDrawerNavLink}-${link.id}`}
      href={link.href}
      isStandalone
      paddingY={8}
      paddingX={12}
      textDecoration="none"
      color={`${colorMode}.Text/Neutral/Default`}
      textStyle="Body/Small/Regular"
      fontSize={7}
      fontWeight={isActive.current ? '600' : '400'}
      _hover={{
        color: `${colorMode}.Text/Neutral/Support`,
      }}
      _active={{
        color: `${colorMode}.Text/Neutral/Support`,
        fontWeight: 'Normal',
      }}
      onClick={() => {
        onLinkClick();
        trackHeaderLinkClick({
          title: link.title ?? '',
          url: link.href,
        });
      }}
    >
      {link.title}
    </Link>
  );
};

export { HeaderDrawerNavLink };
