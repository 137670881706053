import { FacebookIcon } from '@allergan-data-labs/alle-elements-icons/24/facebookIcon';
import { InstagramIcon } from '@allergan-data-labs/alle-elements-icons/24/instagramIcon';
import { YoutubeIcon } from '@allergan-data-labs/alle-elements-icons/24/youtubeIcon';

export const socialLinks = [
  {
    id: 'facebook',
    url: 'https://www.facebook.com/alle/',
    width: '24px',
    icon: FacebookIcon,
  },
  {
    id: 'instagram',
    url: 'https://www.instagram.com/alle/',
    width: '23px',
    icon: InstagramIcon,
  },
  {
    id: 'youtube',
    url: 'https://www.youtube.com/@alle',
    width: '26px',
    icon: YoutubeIcon,
  },
];
