import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from '@allergan-data-labs/alle-elements-layout';
import { useToast } from '@allergan-data-labs/alle-elements-toast';

import {
  INACTIVITY_TIMER_STORAGE_KEY,
  useInactivityTimer,
} from '@packages/core-components/src/hooks/useInactivityTimer';

import { INACTIVITY_TIMER_CONFIG } from '@packages/core-components/src/utils/timerValidations';
import { useAlleTreatments } from '@packages/core-split-feature-flag/src/useAlleTreatments';
import { crFeatureKeys } from '@packages/core-split-feature-flag/src/consumer';

import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';
import { Routes } from '../../constants/routes';
import { InactivityTimerModal } from './inactivityTimerModal';
import { LoggedOutToast } from './loggedOutToast';
import { getLocaleTimeStringFromMilliseconds } from './helpers/times';

const InactivityTimerContainer = () => {
  const [localStorageItem, setLocalStorageItem] = useState<string | null>(null);
  const { currentUser, authState } = useAuth();
  const splitTreatments = useAlleTreatments({
    splitNames: [crFeatureKeys.security.inactivityTimerModal],
    key: currentUser?.id || undefined,
    attributes: {
      alle_id: currentUser?.id ?? '',
    },
  });

  const history = useHistory();
  const showToast = useToast();

  const isInactivityTimerModalEnabled =
    authState.matches(AuthState.isLoggedIn) &&
    splitTreatments[crFeatureKeys.security.inactivityTimerModal]?.treatment ===
      'on';

  const handleInactivityTimerLimitExpired = useCallback(() => {
    // TODO: Segment Event Tracking https://adl-technology.atlassian.net/browse/CR-8859
    history.replace(Routes.logout);
  }, [history]);

  const {
    handleDismissNotification,
    handleDismissWarning,
    wasWarningActivated,
  } = useInactivityTimer({
    onInactivityTimerLimitExpired: handleInactivityTimerLimitExpired,
    isEnabled: isInactivityTimerModalEnabled,
  });

  const handleClose = useCallback(() => {
    handleDismissWarning();
    // TODO: Segment Event Tracking https://adl-technology.atlassian.net/browse/CR-8859
  }, [handleDismissWarning]);

  const showLoggedOutToast = useCallback(
    ({ message }: { message: string }) => {
      if (!localStorageItem) return;

      setTimeout(() => {
        showToast({
          variant: 'neutral',
          duration: null,
          isClosable: true,
          render: () => <LoggedOutToast message={message} />,
          showIcon: false,
          onCloseComplete: () => {
            handleDismissNotification();
          },
        });

        setLocalStorageItem(null);
      }, 1000);
    },
    [localStorageItem, handleDismissNotification, showToast]
  );

  useEffect(() => {
    if (INACTIVITY_TIMER_STORAGE_KEY) {
      const item = localStorage.getItem(INACTIVITY_TIMER_STORAGE_KEY);
      setLocalStorageItem(item);
    }
  }, [setLocalStorageItem]);

  useEffect(() => {
    if (
      !isInactivityTimerModalEnabled &&
      authState.matches(AuthState.isNotLoggedIn) &&
      localStorageItem
    ) {
      const storageItem = JSON.parse(localStorageItem);

      if (storageItem?.message) {
        showLoggedOutToast({
          message: storageItem.message,
        });
      }
    }
  }, [
    authState,
    isInactivityTimerModalEnabled,
    localStorageItem,
    showLoggedOutToast,
  ]);

  return (
    <>
      {isInactivityTimerModalEnabled ? (
        <Container data-testid="inactivity-timer-container">
          <InactivityTimerModal
            isOpen={wasWarningActivated}
            bodyText={`Due to inactivity, you'll be logged out at ${getLocaleTimeStringFromMilliseconds(
              INACTIVITY_TIMER_CONFIG.DEFAULT_OFFSET
            )}. Please click
          below to stay signed in.`}
            buttonText="Continue Session"
            onClose={handleClose}
          />
        </Container>
      ) : null}
    </>
  );
};

export { InactivityTimerContainer };
