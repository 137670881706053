import React from 'react';
import { useHistory } from 'react-router';

import { ButtonGroup, Button } from '@allergan-data-labs/alle-elements-button';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';

import { Routes } from '../../../../constants/routes';
import { useHeaderTrackingEvents } from '../../helpers/useHeaderTrackingEvents';
import { testIds } from '../../_testIds';

export type HeaderDrawerAccountProps = {
  keepPathOnLogin?: boolean;
};

const HeaderDrawerAccount = ({ keepPathOnLogin }: HeaderDrawerAccountProps) => {
  const history = useHistory();
  const location = history.location;

  const { Link } = useEnvironmentContext();

  const { trackLoginLinkClick, trackJoinAlleClick } = useHeaderTrackingEvents();

  return (
    <ButtonGroup alignItems="center" gap={8} marginTop={'auto'} width="100%">
      <Link
        data-testid={testIds.headerDrawerAccountLogin}
        href={Routes.login}
        flex={1}
        referrerData={{
          from: {
            pathname: keepPathOnLogin ? location.pathname : '',
            search: location.search ?? '',
          },
        }}
        onClick={trackLoginLinkClick}
      >
        <Button size="sm" variant="outline" width="100%">
          Log In
        </Button>
      </Link>
      <Link
        data-testid={testIds.headerDrawerAccountJoin}
        href={Routes.registrationPhoneNumber}
        flex={1}
        referrerData={{
          from: {
            pathname: keepPathOnLogin ? location.pathname : '',
            search: location.search ?? '',
          },
        }}
        marginInlineStart={`0 !important`}
        onClick={trackJoinAlleClick}
      >
        <Button size="sm" width="100%">
          Join Allē
        </Button>
      </Link>
    </ButtonGroup>
  );
};

export { HeaderDrawerAccount };
