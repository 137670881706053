import { Consumer_Tier } from '@packages/core-alle-graphql-types';

import { useAuth } from '../../../auth/AuthProvider';
import { useCmsPreview } from '../../../hooks/useCmsPreview';
import { AuthState } from '../../../auth/authMachine';

type HeaderColors = {
  bgColor: string;
  borderColor: string;
  menuIconColor: string;
  navLinkColor: string;
  navLinkHoverColor: string;
  nestedNavLinkColor: string;
  nestedNavLinkHoverColor: string;
  isLogoDark: boolean;
  avatarBg: string;
  avatarForeground: string;
};

export const defaultStyles: HeaderColors = {
  bgColor: `light.Section bg/Neutral/Base`,
  borderColor: `light.Border/Neutral/Subtle 2`,
  menuIconColor: `dark.Section bg/Neutral/Base`,
  navLinkColor: 'light.Text/Neutral/Interactive/Enable',
  navLinkHoverColor: 'light.Text/Neutral/Interactive/Hover',
  nestedNavLinkColor: 'light.Text/Neutral/Interactive/Enable',
  nestedNavLinkHoverColor: 'light.Text/Neutral/Interactive/Hover',
  isLogoDark: false,
  avatarBg: '',
  avatarForeground: '',
};

export const loggedInStyles: HeaderColors = {
  ...defaultStyles,
  bgColor: `light.Section bg/Brand/Mute 2`,
  borderColor: `light.Border/Neutral/Default 08`,
  avatarBg: '#EFE1DD',
  avatarForeground: '#7d574d',
};

export const aListStyles: HeaderColors = {
  bgColor: `dark.Section bg/Neutral/Base`,
  borderColor: `dark.Border/Neutral/Subtle 2`,
  menuIconColor: `light.Section bg/Neutral/Base`,
  navLinkColor: 'dark.Text/Neutral/Interactive/Enable',
  navLinkHoverColor: 'dark.Text/Neutral/Interactive/Hover',
  nestedNavLinkColor: 'light.Text/Neutral/Interactive/Enable',
  nestedNavLinkHoverColor: 'light.Text/Neutral/Interactive/Hover',
  isLogoDark: true,
  avatarBg: '#1C1B1B',
  avatarForeground: '#C3A45E',
};

const useHeaderColors = (): HeaderColors => {
  const { currentUser, authState } = useAuth();
  const { cmsLoggedInPreview } = useCmsPreview();

  const isLoggedIn =
    cmsLoggedInPreview ??
    (!!currentUser || authState.matches(AuthState.isLoggedIn));

  const isAList = currentUser?.loyalty?.tier === Consumer_Tier.AList;

  if (isLoggedIn && !isAList) return loggedInStyles;
  else if (isAList) return aListStyles;
  return defaultStyles;
};

export { useHeaderColors };
