import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';

import { useHeaderColors } from '../../helpers/useHeaderColors';
import { useHeaderTrackingEvents } from '../../helpers/useHeaderTrackingEvents';
import { HeaderLink } from '../../types';
import { testIds } from '../../_testIds';

export type HeaderNavLinkProps = {
  isNested?: boolean;
  link: HeaderLink;
};

const HeaderNavLink = ({ isNested, link }: HeaderNavLinkProps) => {
  const history = useHistory();
  const currentPath = history.location.pathname;

  const isActive = useRef(currentPath === link.href);

  const { Link } = useEnvironmentContext();

  const {
    navLinkColor,
    navLinkHoverColor,
    nestedNavLinkColor,
    nestedNavLinkHoverColor,
  } = useHeaderColors();
  const { trackHeaderLinkClick } = useHeaderTrackingEvents();

  // ensures the link style does not change until the menu is closed
  // avoids causing visual jumps
  useEffect(() => {
    setTimeout(() => {
      isActive.current = currentPath === link.href;
    }, 150);
  }, [currentPath, link.href]);

  return (
    <Link
      key={link.id}
      data-testid={`${testIds.headerNavLink}-${link.id}`}
      href={link.href}
      isStandalone
      variant="action"
      paddingY={isNested ? 8 : ''}
      paddingX={isNested ? 12 : ''}
      fontSize="14px"
      fontWeight={isActive.current ? '600' : '400'}
      color={isNested ? nestedNavLinkColor : navLinkColor}
      _hover={{
        textDecoration: 'none',
        color: isNested ? nestedNavLinkHoverColor : navLinkHoverColor,
      }}
      onClick={() =>
        trackHeaderLinkClick({
          title: link.title ?? '',
          url: link.href,
        })
      }
    >
      {link.title}
    </Link>
  );
};

export { HeaderNavLink };
