import { ConsumerTierAListTypes } from './alistTypes';
import { LoyaltyPoints, Maybe } from '@packages/core-alle-graphql-types';
import moment from 'moment';
import { TierAListTypes } from './alistTypes';
import { isDefined } from '../../../../helpers/consumerWalletRewards';
moment.defaultFormat = 'MM.DD.YY';

export const currentYear = moment().year();

export const alistYear = (value: Maybe<string> | undefined) => {
  return value ? moment(value, moment.defaultFormat).year() : currentYear;
};

export const getAListTierProgressType = (points: LoyaltyPoints) => {
  const { tierPoints, tier, tierThreshold, tierMemberSince } = points;
  if (isDefined(tierPoints) && isDefined(tierThreshold) && tierMemberSince) {
    if (tierPoints < tierThreshold && tier === 'MEMBER') {
      return ConsumerTierAListTypes.Member;
    } else if (tierPoints < tierThreshold && tier === 'A_LIST') {
      return ConsumerTierAListTypes.ALister;
    } else if (
      tierPoints >= tierThreshold &&
      alistYear(tierMemberSince?.format) === currentYear
    ) {
      return ConsumerTierAListTypes.NewALister;
    } else if (
      tierPoints >= tierThreshold &&
      tier === 'A_LIST' &&
      alistYear(tierMemberSince?.format) < currentYear
    ) {
      return ConsumerTierAListTypes.ReturningALister;
    }
  }

  return ConsumerTierAListTypes.Member;
};

export const mapTierTypes = (type: TierAListTypes) => {
  switch (type) {
    case ConsumerTierAListTypes.Member:
      return 'member';
    case ConsumerTierAListTypes.ALister:
      return 'alister';
    case ConsumerTierAListTypes.NewALister:
      return 'newAlister';
    case ConsumerTierAListTypes.ReturningALister:
      return 'returningAlister';
    default:
      return 'member';
  }
};
