import { useHistory } from 'react-router';
import { __CMS_PREVIEW__ } from '../application.config';

export const CMS_LOGIN = 'cms-login';

const useCmsPreview = () => {
  const history = useHistory();
  const cmsLoggedInPreview =
    (__CMS_PREVIEW__ &&
      history.location.search &&
      history.location.search.includes(`${CMS_LOGIN}=true`)) ||
    undefined;
  const cmsLoggedOutPreview =
    (__CMS_PREVIEW__ &&
      history.location.search &&
      history.location.search.includes(`${CMS_LOGIN}=false`)) ||
    undefined;

  return {
    cmsLoggedInPreview,
    cmsLoggedOutPreview,
  };
};

export { useCmsPreview };
