import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { ErrorOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/errorOutlineIcon';

import { ErrorBoundary } from '../error-boundary/errorBoundary';

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};

const logError = (error: Error, info: { componentStack: string }) => {
  console.error(error, info);
};

const Fallback = () => {
  return (
    <Flex
      width="100%"
      height="100vh"
      data-testid="error-page-container"
      alignItems="center"
      justifyContent="center"
    >
      <Flex alignItems="center" flexDir="column">
        <Box
          backgroundColor="#FEE1DE"
          borderRadius="50%"
          padding="12px"
          marginBottom="16px"
        >
          <ErrorOutlineIcon color="#DE3B2D" />
        </Box>
        <Flex flexDir="column" rowGap="4px" maxWidth="300px">
          <Text
            margin={0}
            textAlign="center"
            fontWeight="500"
            fontSize="16px"
            lineHeight="24px"
            letterSpacing="-0.2px"
          >
            Error
          </Text>
          <Text
            color="#787676"
            textAlign="center"
            margin={0}
            fontSize="14px"
            lineHeight="24px"
            letterSpacing="-0.2px"
          >
            An unknown error has occurred. Please delete your cookies and try
            again.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

/**
 * This Error boundary is only meant to be used at the very top level of the component tree (i.e.: router.tsx).
 * At this level, we do not have access to most providers, including the one that specifies theme values.
 * All style rules here need to be explicitly set for the Fallback Component.
 * If you need a generic ErrorBoundary component see /core-components/src/components/error-boundary/general-error-boundary,
 * or better yet, implement a custom one using error-boundary.
 */
const GlobalErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
    {children}
  </ErrorBoundary>
);

export { GlobalErrorBoundary };
