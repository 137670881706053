import { useMemo, useCallback } from 'react';

import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';

import { useAuth } from '../../../auth/AuthProvider';
import { AuthState } from '../../../auth/authMachine';
import { Routes } from '../../../constants/routes';

const useHeaderTrackingEvents = () => {
  const { trackConsumer } = useSegment();
  const { authState } = useAuth();
  const isLoggedIn = authState.matches(AuthState.isLoggedIn);

  const eventAttribtes = useMemo(
    () => ({
      action_source: 'consumer web',
      invoked_by: 'consumer',
      user_type: 'consumer',
      event_type: 'engagement',
      user_status: isLoggedIn ? 'logged_in' : 'logged_out',
    }),
    [isLoggedIn]
  );

  const trackAlleLogoClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: isLoggedIn
          ? 'Logged In - Allē Logo'
          : 'Logged Out - Allē Logo',
        explainer: 'Tracking user engagement with top navigation',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Allē',
      url: `${Routes.home}`,
    });
  }, [trackConsumer, isLoggedIn, eventAttribtes]);

  const trackLoginLinkClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: 'Log in Button',
        explainer: 'Tracking user engagement with top navigation',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Log in',
      url: `${Routes.login}`,
    });
  }, [trackConsumer, eventAttribtes]);

  const trackJoinAlleClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        ...eventAttribtes,
        activator: 'Join Allē Button',
        explainer: 'Tracking user engagement with top navigation',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Join Allē',
      url: `${Routes.registration}`,
    });
  }, [trackConsumer, eventAttribtes]);

  const trackProfileAvatarClick = useCallback(() => {
    trackConsumer()?.elementEngaged({
      action: 'Open',
      event: {
        ...eventAttribtes,
        activator: 'Top Nav',
        explainer: 'Tracking user engagement with top navigation',
        extra_data: {
          properties: 'Header User Avatar',
        },
      },
    });
  }, [trackConsumer, eventAttribtes]);

  const trackHamburgerMenuClick = useCallback(() => {
    trackConsumer()?.elementEngaged({
      action: 'Open',
      event: {
        ...eventAttribtes,
        activator: 'Top Nav',
        explainer: 'Tracking user engagement with top navigation',
        extra_data: {
          properties: 'Hamburger Menu',
        },
      },
    });
  }, [trackConsumer, eventAttribtes]);

  const trackHeaderLinkClick = useCallback(
    ({
      brand,
      title,
      url,
    }: {
      brand?: string;
      title?: string;
      url?: string;
    }) => {
      trackConsumer()?.elementEngaged({
        action: 'Click header nav link',
        element_type: 'Header Link',
        event: {
          ...eventAttribtes,
          activator: 'Top Nav',
          explainer:
            'Tracking user engagement with link in top nav or nav drawer',
          extra_data: {
            brand,
            header: {
              link: title,
            },
            url,
          },
        },
      });
    },
    [trackConsumer, eventAttribtes]
  );

  return {
    trackAlleLogoClick,
    trackLoginLinkClick,
    trackJoinAlleClick,
    trackProfileAvatarClick,
    trackHamburgerMenuClick,
    trackHeaderLinkClick,
  };
};

export { useHeaderTrackingEvents };
