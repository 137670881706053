import { useHistory } from 'react-router-dom';
import { Navigate } from '@packages/core-components/src/components/environment-context/types';
import { windowNavigate } from '@packages/core-components/src/components/environment-context/navigate/windowNavigate';

/**
 *  Function to navigate to various urls, using react router
 */
const useReactRouterNavigation = () => {
  const history = useHistory();

  const navigate: Navigate = (url, options) => {
    const isRelativeUrl = url.startsWith('/');
    if (isRelativeUrl && options?.target !== '_blank') {
      history.push(url);
    } else {
      windowNavigate(url, options);
    }
  };

  return navigate;
};

export { useReactRouterNavigation };
