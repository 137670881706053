import React, { useState, MouseEvent } from 'react';

import { useColorMode } from '@allergan-data-labs/alle-elements-core';
import { Box } from '@allergan-data-labs/alle-elements-layout';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';

import { Routes } from '../../../../constants/routes';
import { useAuth } from '../../../../auth/AuthProvider';
import { AuthState } from '../../../../auth/authMachine';
import { useFooterTrackingEvents } from '../../helpers/useFooterTrackingEvents';
import { FooterNavPrivacyIcon } from './footerNavPrivacyIcon';
import { testIds } from '../../_testIds';
import { FooterNavPrivacyModal } from './footerNavPrivacyModal';

const FooterNavPrivacy = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { authState } = useAuth();
  const isLoggedIn = authState?.matches(AuthState.isLoggedIn);

  const { Link } = useEnvironmentContext();

  const { colorMode } = useColorMode();

  const color =
    colorMode === 'light'
      ? 'dark.Text/Neutral/Interactive/Enable'
      : 'light.Text/Neutral/Interactive/Enable';

  const { trackPrivacyClick } = useFooterTrackingEvents();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    trackPrivacyClick();

    if (!isLoggedIn) {
      event.preventDefault();
      setIsOpen(true);
    }
  };

  return (
    <>
      <Link
        data-testid={testIds.footerNavPrivacy}
        href={Routes.settingsCommunication}
        display="flex"
        alignItems="center"
        gap={4}
        isStandalone={true}
        variant="action"
        colorMode="dark"
        onClick={handleClick}
      >
        <Box as="span" textStyle="Body/Small/Regular" color={color}>
          Your Privacy Choices
        </Box>
        <Box as="span" width="31px" height="14px" display="flex">
          <FooterNavPrivacyIcon />
        </Box>
      </Link>
      <FooterNavPrivacyModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export { FooterNavPrivacy };
