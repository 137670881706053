import React, { useMemo } from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';

import { Box } from '@allergan-data-labs/alle-elements-layout';

import { HeaderDrawerNav } from './headerDrawerNav';
import { HeaderDrawerAccount } from './headerDrawerAccount';
import { testIds } from '../../_testIds';

type HeaderDrawerProps = {
  isOpen?: boolean;
  isLoggedIn?: boolean;
  keepPathOnLogin?: boolean;
  relative?: boolean;
  toggleDrawer: () => void;
};

const HeaderDrawer = ({
  isOpen,
  isLoggedIn,
  keepPathOnLogin,
  relative,
  toggleDrawer,
}: HeaderDrawerProps) => {
  const scrollY = useScrollPosition();

  const topPosition = useMemo(() => {
    return relative ? `${64 - scrollY}px` : '64px';
  }, [relative, scrollY]);

  return (
    <Box data-testid={testIds.headerDrawer} zIndex={1}>
      <Drawer
        isOpen={!!isOpen}
        placement="left"
        isFullHeight={false}
        onClose={toggleDrawer}
      >
        <DrawerOverlay marginTop={topPosition} />
        <DrawerContent
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          backgroundColor={`light.Section bg/Neutral/Mute 1`}
          padding={16}
          paddingBottom={0}
          boxSizing="border-box"
          maxWidth={{
            base: '100vw',
            sm: 360,
          }}
          marginTop={topPosition}
        >
          <DrawerBody
            data-testid={testIds.headerDrawerContent}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap={16}
            paddingTop={0}
            paddingX={0}
            paddingBottom={16}
          >
            <HeaderDrawerNav
              isLoggedIn={isLoggedIn}
              onLinkClick={toggleDrawer}
            />
            {!isLoggedIn ? (
              <HeaderDrawerAccount keepPathOnLogin={keepPathOnLogin} />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export { HeaderDrawer };
