export const testIds = {
  footer: 'footer',
  footerLogo: 'footer-logo',
  footerSocial: 'footer-social',
  footerSocialLink: 'footer-social-link',
  footerCopyright: 'footer-copyright',
  footerCopyrightPrt: 'footer-copyright-prt',
  footerNav: 'footer-nav',
  footerNavLink: 'footer-nav-link',
  footerNavCookies: 'footer-nav-cookies',
  footerNavPrivacy: 'footer-nav-privacy',
  footerNavPrivacyModal: 'footer-nav-privacy-modal',
  footerNavPrivacyModalLogin: 'footer-nav-privacy-modal-login',
  footerNavPrivacyModalCookies: 'footer-nav-privacy-modal-cookies',
  footerNavPrivacyModalRights: 'footer-nav-privacy-modal-rights',
};
