import React from 'react';

import { Flex } from '@allergan-data-labs/alle-elements-layout';

import { loggedInLinks, loggedOutLinks } from '../../constants';
import { HeaderDrawerNavGroup } from './headerDrawerNavGroup';
import { HeaderDrawerNavLink } from './headerDrawerNavLink';
import { testIds } from '../../_testIds';

export type HeaderDrawerNavProps = {
  isLoggedIn?: boolean;
  onLinkClick: () => void;
};

const HeaderDrawerNav = ({ isLoggedIn, onLinkClick }: HeaderDrawerNavProps) => {
  const links = isLoggedIn ? loggedInLinks : loggedOutLinks;

  return (
    <Flex data-testid={testIds.headerDrawerNav} flexDirection="column" gap={8}>
      {links.map((link) =>
        link.items ? (
          <HeaderDrawerNavGroup
            key={link.id}
            {...link}
            onLinkClick={onLinkClick}
          />
        ) : (
          <HeaderDrawerNavLink
            key={link.id}
            link={link}
            onLinkClick={onLinkClick}
          />
        )
      )}
    </Flex>
  );
};

export { HeaderDrawerNav };
