import React from 'react';
import { Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { StackProps, Stack } from '@allergan-data-labs/alle-elements-layout';
import { defaultRichTextRenderOptions } from './defaultRichTextRenderOptions';
import { CustomizeDefaultOptions } from './types';

export type RichTextProps = CustomizeDefaultOptions & {
  /**
   * the rich text json
   */
  richText?: Document;
  /**
   * container props applied to rich text container
   */
  containerProps?: StackProps;
  /**
   * id to be used as data-testid, if not provided then 'rich-text' will be used as data-testid
   */
  testId?: string;
};

/**
 * Common component for implementing Contentful rich text
 */
const RichText = ({
  richText,
  containerProps,
  testId,
  ...customizeDefaultOptions
}: RichTextProps) => {
  if (!richText) return null;

  return (
    <Stack
      className="rich-text"
      data-testid={testId || 'rich-text'}
      whiteSpace={'pre-wrap'} // note: the preserveWhitespace: true option will cause console errors
      // note: contentful adds a trailing p tag that can mess up styles
      // https://github.com/contentful/rich-text/issues/101
      css={{
        'p:last-child:empty': {
          display: 'none',
        },
      }}
      {...containerProps}
    >
      {documentToReactComponents(
        richText,
        defaultRichTextRenderOptions({
          ...customizeDefaultOptions,
        })
      )}
    </Stack>
  );
};

export { RichText };
