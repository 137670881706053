import React from 'react';

import { Button } from '@allergan-data-labs/alle-elements-button';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@allergan-data-labs/alle-elements-modal';

interface InactivityTimerModalProps {
  isOpen: boolean;
  bodyText: string;
  buttonText: string;
  onClose: () => void;
}

const InactivityTimerModal = ({
  isOpen = false,
  bodyText,
  buttonText,
  onClose,
}: InactivityTimerModalProps) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} colorMode="light">
      <ModalOverlay backgroundColor={'light.Section bg/Neutral/Scrim'} />
      <ModalContent
        width={{ base: '100vw', md: '314px' }}
        data-testid="inactivity-warning-modal-content"
      >
        <ModalBody
          color={'light.Text/Neutral/Default'}
          padding={24}
          data-testid="inactivity-warning-modal-body"
        >
          {bodyText}
        </ModalBody>
        <ModalFooter>
          <Button sx={{ width: '100%' }} onClick={onClose}>
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { InactivityTimerModal };
