import React from 'react';

import { Flex } from '@allergan-data-labs/alle-elements-layout';

import { HeaderNavGroup } from './headerNavGroup';
import { HeaderNavLink } from './headerNavLink';
import { loggedInLinks, loggedOutLinks } from '../../constants';
import { testIds } from '../../_testIds';

export type HeaderNavProps = {
  isLoggedIn: boolean;
  isHeaderHidden: boolean;
};

const HeaderNav = ({ isLoggedIn, isHeaderHidden }: HeaderNavProps) => {
  const links = isLoggedIn ? loggedInLinks : loggedOutLinks;

  return (
    <Flex data-testid={testIds.headerNav} alignItems="center" gap={24}>
      {links.map((link) =>
        link.items ? (
          <HeaderNavGroup
            key={link.id}
            isHeaderHidden={isHeaderHidden}
            {...link}
          />
        ) : (
          <HeaderNavLink key={link.id} link={link} />
        )
      )}
    </Flex>
  );
};

export { HeaderNav };
