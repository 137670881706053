import { v4 as uuid } from 'uuid';

export const getAnonymousIdFromAnalytics = () => {
  let anonymousId;
  if (window?.analytics?.user) {
    anonymousId = window?.analytics?.user?.()?.anonymousId();
  }
  return anonymousId;
};

export const getAnonymousId = (
  getStoredAnonymousId: (() => string | undefined) | undefined
) => {
  const newAnonymousId = uuid();
  const anonymousId = getStoredAnonymousId?.();
  return anonymousId ? anonymousId : newAnonymousId;
};
