/**
 * If relative link (starting with '/') will add the base url of current page
 */
const formatUrlWithOrigin = (url: string): string => {
  if (url.startsWith('/')) {
    return `${window?.location?.origin}${url}`;
  }

  return url;
};

export { formatUrlWithOrigin };
