import {
  IBlockFooterFields,
  ILinkFields,
  IStaticCodeFields,
} from '@packages/core-contentful-rest';

const formatCmsFooter = (data: IBlockFooterFields) => {
  return {
    copyright: data?.footerCopy,
    links: data?.linksReference?.[0]?.fields.links.map((link) => ({
      url: (link.fields as ILinkFields).url,
      text: (link.fields as ILinkFields).displayName,
      forceOpenSameTab: (link.fields as ILinkFields).forceOpenSameTab,
      codeId: (link.fields as IStaticCodeFields).codeId,
    })),
  };
};

export { formatCmsFooter };
