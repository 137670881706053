import React from 'react';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { AlleLogo } from '@packages/core-components/src/components/svgs/AlleLogo';

import { Routes } from '../../../constants/routes';
import { useHeaderColors } from '../helpers/useHeaderColors';
import { useHeaderTrackingEvents } from '../helpers/useHeaderTrackingEvents';
import { testIds } from '../_testIds';

const HeaderLogo = () => {
  const { Link } = useEnvironmentContext();

  const { isLogoDark } = useHeaderColors();

  const { trackAlleLogoClick } = useHeaderTrackingEvents();

  return (
    <Link
      data-testid={testIds.headerLogo}
      aria-label="ALLE"
      href={Routes.home}
      variant="action"
      colorMode={isLogoDark ? 'dark' : 'light'}
      display="flex"
      width="50px"
      onClick={trackAlleLogoClick}
    >
      <AlleLogo />
    </Link>
  );
};

export { HeaderLogo };
