import React from 'react';

import { Box } from '@allergan-data-labs/alle-elements-layout';

export type LoggedOutToastSessionStorageType = Partial<{
  message: string;
  loggedOutAt: number;
}>;

export const LoggedOutToastSessionStorageKey = 'aa_logged_out_toast';

interface LoggedOutToastProps {
  message?: string;
}

const LoggedOutToast = ({ message }: LoggedOutToastProps) => {
  return (
    <Box
      data-testid="logged-out-toast"
      textStyle="Body/Medium/Regular"
      textColor={`light.Text/Neutral/Default`}
      width={{ base: 237, md: 432 }}
    >
      {message ? message : 'Please log in again.'}
    </Box>
  );
};

export { LoggedOutToast };
