import { useCallback, useEffect, useRef, useState } from 'react';

import {
  addActivityListeners,
  removeActivityListeners,
} from '../utils/activityListeners';
import {
  INACTIVITY_TIMER_CONFIG,
  validateInactivityTimerConfig,
} from '../utils/timerValidations';

interface UseInactivityTimerProps {
  inactivityTimerLimit?: number;
  inactivityTimerOffset?: number;
  isEnabled?: boolean;
  onInactivityTimerLimitExpired: () => void;
}

interface UseInactivityTimerReturnValues {
  wasWarningActivated: boolean;
  handleDismissNotification: () => void;
  handleDismissWarning: () => void;
}

/**
 * Monitor user's inactivity, and trigger callbacks based on timer limit and offset.
 *
 * @param {number} props.inactivityTimerLimit - Sets time limit on user inactivity. Defaults to 2 hours.
 * @param {number} props.inactivityTimerOffset - Sets time to offset time limit used for warning user. Defaults to 5 minutes before inactivity.
 * @param {boolean} props.isEnabled - Allows enable/disabling of hook. Defaults to false.
 * @param {function} props.onInactivityTimerLimitExpired - Callback to execute when inactivity timer limit expires.
 * @returns {UseInactivityTimerReturnValues} - Abstractions of hooks internal state management
 *
 * @throws Will throw an error when times are not positive numbers or when warning time is not less than inactivity limit time.
 *
 * @example
 * const { handleDismissWarning, handleResetInactivityTimers } = useInactivityTimer({
 *    inactivityTimerLimit: 3600000, // 1 hour
 *    inactivityTimerOffset: 300000, // 5 minutes
 *    isEnabled: false, // state.matches('isLoggedIn') && feature flag is enabled,
 *    onInactivityTimerLimitExpired: () => logout(),
 * })
 */
const useInactivityTimer = ({
  inactivityTimerLimit = INACTIVITY_TIMER_CONFIG.DEFAULT_LIMIT,
  inactivityTimerOffset = INACTIVITY_TIMER_CONFIG.DEFAULT_OFFSET,
  isEnabled = false,
  onInactivityTimerLimitExpired,
}: UseInactivityTimerProps): UseInactivityTimerReturnValues => {
  if (isEnabled) {
    validateInactivityTimerConfig({
      limit: inactivityTimerLimit,
      offset: inactivityTimerOffset,
    });
  }

  // e.g. - Warn at 1 hour and 55 minutes by default = 2 hour timer limit by default - 5 minute timer offset by default
  const warningTime = inactivityTimerLimit - inactivityTimerOffset;

  const inactivityTimersRef = useRef<{
    inactivityTimer: ReturnType<typeof setTimeout> | null;
    warningTimer: ReturnType<typeof setTimeout> | null;
  }>({
    inactivityTimer: null,
    warningTimer: null,
  });
  const warningActiveRef = useRef<boolean>(false);
  const lastActiveResetTimestampRef = useRef<number>(0);
  const inactivityTimerBroadcastChannelRef = useRef<BroadcastChannel | null>(
    null
  );

  const [warningActivated, setWarningActivated] = useState<boolean>(false);

  const createInactivityDataInStorage = useCallback(
    (data: InactivityStorageData) => {
      localStorage.setItem(INACTIVITY_TIMER_STORAGE_KEY, JSON.stringify(data));
    },
    []
  );

  const resetInactivityTimers = useCallback(() => {
    // Prevents reset of inactivity timers when warning is active(e.g. - warning modal open)
    if (warningActiveRef.current) return;

    warningActiveRef.current = false;
    setWarningActivated(false);

    // Set last active timestamp value in Local storage
    const now = Date.now();
    lastActiveResetTimestampRef.current = now;

    createInactivityDataInStorage({
      lastActiveAt: now,
      message: '',
      loggedOutAt: 0,
    });

    // Communicate to all open tabs to update last active timestamp
    if (inactivityTimerBroadcastChannelRef.current) {
      inactivityTimerBroadcastChannelRef.current.postMessage({
        action: INACTIVITY_TIMER_BROADCAST_CHANNEL_ACTIONS.resetTimers,
        timestamp: now,
      });
    }

    if (inactivityTimersRef.current.inactivityTimer) {
      clearTimeout(inactivityTimersRef.current.inactivityTimer);
    }

    if (inactivityTimersRef.current.warningTimer) {
      clearTimeout(inactivityTimersRef.current.warningTimer);
    }

    inactivityTimersRef.current.inactivityTimer = setTimeout(() => {
      const loggedOutAt = Date.now();
      const inactivityInfo = {
        message:
          "Due to inactivity, you've been automatically logged out. Please log in again.",
        loggedOutAt,
      };

      createInactivityDataInStorage({
        lastActiveAt: lastActiveResetTimestampRef.current,
        ...inactivityInfo,
      });

      if (inactivityTimerBroadcastChannelRef.current) {
        inactivityTimerBroadcastChannelRef.current.postMessage({
          action:
            INACTIVITY_TIMER_BROADCAST_CHANNEL_ACTIONS.updateInactivityInfo,
          ...inactivityInfo,
        });
      }

      onInactivityTimerLimitExpired();
    }, inactivityTimerLimit);

    inactivityTimersRef.current.warningTimer = setTimeout(() => {
      if (!warningActiveRef.current) {
        warningActiveRef.current = true;
        setWarningActivated(true);
      }
    }, warningTime);
  }, [
    inactivityTimerLimit,
    warningTime,
    createInactivityDataInStorage,
    onInactivityTimerLimitExpired,
    setWarningActivated,
  ]);

  /**
   * e.g. User changed browser tabs
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
   */
  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      // Clears timers on hidden tab
      if (inactivityTimersRef.current.inactivityTimer) {
        clearTimeout(inactivityTimersRef.current.inactivityTimer);
        inactivityTimersRef.current.inactivityTimer = null;
      }

      if (inactivityTimersRef.current.warningTimer) {
        clearTimeout(inactivityTimersRef.current.warningTimer);
        inactivityTimersRef.current.warningTimer = null;
      }
    } else {
      const now = Date.now();
      const storageItem = localStorage.getItem(INACTIVITY_TIMER_STORAGE_KEY);
      let lastActiveAtTimestamp = now;

      if (storageItem) {
        const item = JSON.parse(storageItem);
        lastActiveAtTimestamp = item.lastActiveAt || now;
      }

      const timeElapsedSinceLastActive: number = now - lastActiveAtTimestamp;

      if (timeElapsedSinceLastActive >= inactivityTimerLimit) {
        onInactivityTimerLimitExpired();
      } else {
        resetInactivityTimers();
      }
    }
  }, [
    inactivityTimerLimit,
    resetInactivityTimers,
    onInactivityTimerLimitExpired,
  ]);

  // e.g. Call within a close button handler
  const dismissWarning = useCallback(() => {
    warningActiveRef.current = false;
    setWarningActivated(false);

    if (inactivityTimerBroadcastChannelRef.current) {
      inactivityTimerBroadcastChannelRef.current.postMessage({
        action: INACTIVITY_TIMER_BROADCAST_CHANNEL_ACTIONS.warningDismissed,
        timestamp: Date.now(),
      });
    }
    resetInactivityTimers();
  }, [
    inactivityTimerBroadcastChannelRef,
    resetInactivityTimers,
    setWarningActivated,
  ]);

  const dismissNotification = useCallback(() => {
    localStorage.removeItem(INACTIVITY_TIMER_STORAGE_KEY);
  }, []);

  useEffect(() => {
    if (!isEnabled) return;

    const inactivityTimers = inactivityTimersRef.current;

    // Attach activity listeners
    addActivityListeners(throttle(resetInactivityTimers, THROTTLE_DELAY));
    // Reset timers
    resetInactivityTimers();

    // Attach visibility change listener - e.g. changed browser tabs
    document.addEventListener('visibilitychange', handleVisibilityChange);

    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
     * Allows basic communication between browsing contexts (that is, windows, tabs,
     * frames, or iframes) and workers on the same origin.
     */
    if ('BroadcastChannel' in window) {
      inactivityTimerBroadcastChannelRef.current = new BroadcastChannel(
        INACTIVITY_TIMER_BROADCAST_CHANNEL_NAME
      );
      inactivityTimerBroadcastChannelRef.current.onmessage = (event) => {
        if (
          event.data?.action ===
          INACTIVITY_TIMER_BROADCAST_CHANNEL_ACTIONS.resetTimers
        ) {
          const subscriberTimestamp = event.data.timestamp;

          if (subscriberTimestamp > lastActiveResetTimestampRef.current) {
            resetInactivityTimers();
          }
        } else if (
          event.data?.action ===
          INACTIVITY_TIMER_BROADCAST_CHANNEL_ACTIONS.warningDismissed
        ) {
          warningActiveRef.current = false;
          setWarningActivated(false);
        }
      };
    }

    return () => {
      removeActivityListeners(throttle(resetInactivityTimers, THROTTLE_DELAY));
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      if (inactivityTimers.warningTimer) {
        clearTimeout(inactivityTimers.warningTimer);
      }

      if (inactivityTimers.inactivityTimer) {
        clearTimeout(inactivityTimers.inactivityTimer);
      }

      if (inactivityTimerBroadcastChannelRef.current) {
        inactivityTimerBroadcastChannelRef.current.close();
        inactivityTimerBroadcastChannelRef.current = null;
      }
    };
  }, [isEnabled, handleVisibilityChange, resetInactivityTimers]);

  return {
    wasWarningActivated: warningActivated,
    handleDismissNotification: dismissNotification,
    handleDismissWarning: dismissWarning,
  };
};

// Local storage
export interface InactivityStorageData {
  lastActiveAt: number;
  message: string;
  loggedOutAt: number;
}
export const INACTIVITY_TIMER_STORAGE_KEY = 'alle-inactivity-info';

// BroadcastChannel
export const INACTIVITY_TIMER_BROADCAST_CHANNEL_NAME =
  'inactivity-timer-broadcast-channel';
export const INACTIVITY_TIMER_BROADCAST_CHANNEL_ACTIONS = {
  updateInactivityInfo: 'UPDATE_INACTIVITY_INFO',
  resetTimers: 'RESET_TIMERS',
  warningDismissed: 'WARNING_DISMISSED',
} as const;

// Utils
export const THROTTLE_DELAY = 2000; // 2 seconds
export const throttle = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number
): T => {
  let lastCall = 0;

  return ((...args: unknown[]) => {
    const now = Date.now();

    if (now - lastCall >= delay) {
      lastCall = now;
      func(...args);
    }
  }) as T;
};

export { useInactivityTimer };
