import React from 'react';

import { useColorMode } from '@allergan-data-labs/alle-elements-core';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@allergan-data-labs/alle-elements-modal';
import { Box } from '@allergan-data-labs/alle-elements-layout';
import { TextButton } from '@allergan-data-labs/alle-elements-button';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';

import { Routes } from '../../../../constants/routes';
import { ABBVIE_URLS } from '../../../../constants/urls';
import { useFooterTrackingEvents } from '../../helpers/useFooterTrackingEvents';
import { testIds } from '../../_testIds';

export type FooterNavPrivacyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const FooterNavPrivacyModal = ({
  isOpen,
  onClose,
}: FooterNavPrivacyModalProps) => {
  const { Link } = useEnvironmentContext();

  const { colorMode } = useColorMode();

  const {
    trackPrivacyModalLoginClick,
    trackPrivacyModalCookiesClick,
    trackPrivacyModalRightsClick,
  } = useFooterTrackingEvents();

  const handleLoginClick = () => {
    trackPrivacyModalLoginClick();
    onClose();
  };

  const handleCookiesClick = () => {
    (window as any).OneTrust?.ToggleInfoDisplay();
    trackPrivacyModalCookiesClick();
    onClose();
  };

  const handleRightsClick = () => {
    trackPrivacyModalRightsClick();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} size="sm" onClose={onClose}>
      <ModalOverlay backgroundColor={`${colorMode}.Section bg/Neutral/Scrim`} />
      <ModalContent data-testid={testIds.footerNavPrivacyModal}>
        <ModalHeader display="flex" justifyContent="space-between">
          <Box textStyle="Headline/Extra small/Primary bold">
            Your Privacy Choices
          </Box>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={24}>
          <Box>
            Your Privacy Choices are available for logged in Allē Members
            only.&nbsp;
            <Link
              data-testid={testIds.footerNavPrivacyModalLogin}
              href={Routes.login}
              variant="action"
              onClick={handleLoginClick}
            >
              Sign In.
            </Link>
          </Box>
          <Box paddingBottom={12}>
            You can also adjust your logged out&nbsp;
            <TextButton
              data-testid={testIds.footerNavPrivacyModalCookies}
              display="inline-flex"
              padding={0}
              textDecoration="underline"
              onClick={handleCookiesClick}
            >
              Cookie Settings Here
            </TextButton>
            , or otherwise exercise your privacy choices through&nbsp;
            <Link
              data-testid={testIds.footerNavPrivacyModalRights}
              href={ABBVIE_URLS.dataSubjectRightsRequestForm}
              variant="action"
              target="_blank"
              onClick={handleRightsClick}
            >
              AbbVie&lsquo;s Data Subject Rights Tool
            </Link>
            .&nbsp;
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { FooterNavPrivacyModal };
