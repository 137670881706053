import { createContext, useContext } from 'react';
import { CONSUMER_TRACKING_VALUE } from '@packages/core-segment-tracking/src/consumerTrackingValues';

type EventTrackingData = {
  sectionIdentifier?: string;
  userStatus?:
    | typeof CONSUMER_TRACKING_VALUE.userStatusLoggedIn
    | typeof CONSUMER_TRACKING_VALUE.userStatusLoggedOut;
};

type DomainCmsContextType = {
  eventTrackingData: EventTrackingData;
};

/**
 * context to hold values used within domain-cms components
 */
const DomainCmsContext = createContext<DomainCmsContextType>({
  eventTrackingData: {},
});

const useDomainCmsContext = () => {
  const context = useContext(DomainCmsContext);
  if (context === undefined) {
    throw new Error(
      'useDomainCmsContext must be used within an DomainCmsContext.Provider'
    );
  }
  return context;
};

export { DomainCmsContext, useDomainCmsContext };
