import { ApolloProvider } from '@apollo/react-hooks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { useLocation } from 'react-router-dom';

// Apollo Client
import { client } from '@packages/core-apollo-client/src/client';
import { AlleApolloClientWeb } from '@packages/core-apollo-client/src/client-web';

// shared components
// deprecated
import { LinkProvider } from '@packages/deprecated-component-library/src/core/linkContext';
import { LoadingProvider } from '@packages/deprecated-component-library/src/loadingBar/loadingContext';
import { MarkdownDialogProvider } from '@packages/deprecated-component-library/src/markdownDialogStateManagement';
import { NotificationProvider } from '@packages/deprecated-component-library/src/notificationBar/notificationContext';
import { StyledComponentsThemeProvider } from '@packages/deprecated-component-library/src/styledComponents/themeProvider';
import { ContentViewProvider } from '@packages/deprecated-consumer-component-library/src/content/contentViewContext';

// shared
import { SegmentProvider } from '@packages/core-segment-tracking/src/segmentContext';
import { DatadogProvider } from '@packages/core-datadog-logging/src/logger';

// consumer
import { AuthProvider } from './auth/AuthProvider';
import { AlleThemeProvider, ThemeProvider } from './components/themeProvider';
import { getAccessToken } from './auth/oktaClient';
import { routeConfig } from './routeConfig';
import { EnvironmentContextProvider } from './environmentContextProvider';
import { __CMS_PREVIEW__ } from './application.config';
import { AlleMetadata } from './utils/alleMetadata';

// Initialize the global Apollo client
AlleApolloClientWeb({
  name: 'consumer-web',
  version: __APP_VERSION__,
  getAccessToken,
});

const queryClient = new QueryClient();

const Providers: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <>
      <AlleMetadata />
      <DatadogProvider
        application={__CMS_PREVIEW__ ? 'cmsPreview' : 'consumer'}
        version={__APP_VERSION__}
      >
        <ApolloProvider client={client}>
          <QueryClientProvider client={queryClient}>
            <SegmentProvider>
              <AuthProvider>
                <NotificationProvider pathname={pathname}>
                  <LinkProvider routes={routeConfig}>
                    <CookiesProvider>
                      <ThemeProvider>
                        <AlleThemeProvider>
                          <EnvironmentContextProvider>
                            <ContentViewProvider>
                              <LoadingProvider>
                                <MarkdownDialogProvider>
                                  <StyledComponentsThemeProvider>
                                    {children}
                                  </StyledComponentsThemeProvider>
                                </MarkdownDialogProvider>
                              </LoadingProvider>
                            </ContentViewProvider>
                          </EnvironmentContextProvider>
                        </AlleThemeProvider>
                      </ThemeProvider>
                    </CookiesProvider>
                  </LinkProvider>
                </NotificationProvider>
              </AuthProvider>
            </SegmentProvider>
          </QueryClientProvider>
        </ApolloProvider>
      </DatadogProvider>
    </>
  );
};

export { Providers };
