/**
 * Creates an HTTP header object with a single key-value pair.
 *
 * @param key - The name of the header.
 * @param value - The value of the header.
 * @returns An object representing the HTTP header.
 */
export const createHeader = (key: string, value: string) => {
  const headers = {
    [key]: value,
  };
  return headers;
};
