import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

import { shared } from '@packages/core-split-feature-flag/src/shared';
import { useClearAllNotifications } from '@packages/deprecated-component-library/src/hooks/useClearAllNotifications';
import { useDemoNotification } from '@packages/deprecated-component-library/src/hooks/useDemoNotification';
import { useMaintenanceNotification } from '@packages/deprecated-component-library/src/hooks/useMaintenanceNotification';
import { GlobalLoadingBar } from '@packages/deprecated-component-library/src/loadingBar/globalLoadingBar';
import { useScrollToTop } from '@packages/deprecated-component-library/src/hooks/useScrollToTop';
import { GlobalStyle } from '@packages/deprecated-component-library/src/core/globalStyle';
import { usePrivacyBanner } from '@packages/deprecated-component-library/src/hooks/usePrivacyBanner';
import { CookieNotificationBanner } from '@packages/deprecated-consumer-component-library/src/cookieNotificationBanner/cookieNotificationBanner';
import {
  SiteNavigated,
  ElementEngaged,
} from '@packages/core-segment-tracking/src/generated';
import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';
import { useSegmentPageTracking } from '@packages/deprecated-consumer-component-library/src/hooks/useSegmentPageTracking';
import { useUTMPageTracking } from '@packages/deprecated-consumer-component-library/src/hooks/trackingProviders';
import { useSaveTikTokClickId } from '@packages/core-segment-tracking/src/ttclidValueMiddleware';
import { useSaveSearchParams } from '@packages/core-segment-tracking/src/platformSearchParams';
import { useAlleTreatments } from '@packages/core-split-feature-flag/src/useAlleTreatments';

import {
  ONETRUST_BANNER_CLOSED_COOKIE_NAME,
  ONETRUST_COOKIE_NAME,
} from './application.config';
import { useAuth } from './auth/AuthProvider';
import { AuthState } from './auth/authMachine';
import { baseUrl } from './application.config';
import { ABBVIE_URLS } from './constants/urls';

const App: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { trackConsumer } = useSegment();
  const { authState } = useAuth();
  const isLoggedIn = authState?.matches(AuthState.isLoggedIn);
  const { search, pathname } = useLocation();
  const shouldRunTracking = !['debug'].includes(pathname.split('/')[1]);
  const { saveTikTokClickId } = useSaveTikTokClickId();
  const { saveSearchParams } = useSaveSearchParams();
  useScrollToTop();
  useClearAllNotifications();
  useSegmentPageTracking(baseUrl, undefined, shouldRunTracking);
  useDemoNotification();
  const { saveUtmTrackingParams } = useUTMPageTracking();
  const splitTreatments = useAlleTreatments({
    splitNames: [
      shared.privacyTermsNotification,
      shared.maintenanceNotificationBanner,
    ],
  });
  const maintenanceNotificationBanner =
    splitTreatments[shared.maintenanceNotificationBanner];
  useMaintenanceNotification({
    cookieName: 'consumer-scheduled-maintenance',
    isMaintenanceEnabled: maintenanceNotificationBanner.treatment === 'on',
    bannerCopy: maintenanceNotificationBanner.config
      ? JSON.parse(maintenanceNotificationBanner.config).bannerCopy
      : '',
    size: 'large',
  });

  const handleClose = useCallback(() => {
    const buttonDataDeprecated: ElementEngaged = {
      action: 'Close Banner',
      event: {
        activator: 'Close Button',
        explainer: 'User closed privacy banner',
      },
    };

    trackConsumer()?.elementEngaged(buttonDataDeprecated);
  }, [trackConsumer]);

  const handleLinkClick = React.useCallback(() => {
    const linkData: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Link',
        explainer:
          'Tracking user viewed privacy notice from notification banner',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: authState.matches(AuthState.isLoggedIn)
          ? 'logged_in'
          : 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'browse',
      event_source_url: `${window.location.href}`,
      text: ABBVIE_URLS.privacyPolicy,
      url: ABBVIE_URLS.privacyPolicy,
    };

    trackConsumer()?.siteNavigated(linkData);
  }, [trackConsumer, authState]);

  usePrivacyBanner(
    handleClose,
    handleLinkClick,
    splitTreatments[shared.privacyTermsNotification].treatment === 'on'
  );

  useEffect(() => {
    saveUtmTrackingParams(search);
    saveTikTokClickId(search);
    saveSearchParams(search);
  }, [search, saveUtmTrackingParams, saveTikTokClickId, saveSearchParams]);

  useEffect(() => {
    console.log('App version: ', __APP_VERSION__);
  }, []);

  return (
    <>
      <GlobalStyle />
      <GlobalLoadingBar />
      {!isLoggedIn && (
        <CookieNotificationBanner
          name={ONETRUST_COOKIE_NAME}
          closedNoSelectionCookieName={ONETRUST_BANNER_CLOSED_COOKIE_NAME}
        />
      )}
      {children}
    </>
  );
};

export { App };
