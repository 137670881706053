/* istanbul ignore file */

import { Routes } from './constants/routes';

export const baseUrl = window.location.origin;

const TWO_HOURS_IN_SECONDS = 7200;

export const customOktaSettings = {
  redirectUri: `${baseUrl}${Routes.authCallback}`,
  // Allow users to authenticate even if their clock is not accurate for up two hours
  // This fixes an issue where some users see "JWT was issued in the future" errors
  maxClockSkew: TWO_HOURS_IN_SECONDS,
};

export const oktaTokenIds = {
  accessToken: 'consumer.accessTokenId',
  idToken: 'consumer.idToken',
};

/** @deprecated We are relying on OneTrust to manage cookies, should use ONETRUST_COOKIE_NAME (max age also handled by OneTrust)  */
export const cookiesSettings = {
  name: process.env.REACT_APP_COOKIES_NAME || 'alleConsent',
  maxAge: +(process.env.REACT_APP_COOKIES_MAX_AGE || 31536000),
};
export const ONETRUST_COOKIE_NAME = 'OptanonAlertBoxClosed'; // max age handled by OneTrust
export const ONETRUST_BANNER_CLOSED_COOKIE_NAME = 'OneTrustClose'; // used to track closing of cookie banner with no user selection (session cookie)

export const __CMS_PREVIEW__ =
  process.env.REACT_APP_CMS_HOST === 'preview.contentful.com';

export const __PROD__ = process.env.NODE_ENV === 'production';
export const __DEV__ = process.env.NODE_ENV === 'development';

export const isAlleDown = process.env.REACT_APP_ALLE_DOWN === 'true';

export const inactivityTimer = {
  session: 3600000, // 1 hours
};

export const SPLIT_DEBUGGER_ENABLED =
  process.env.REACT_APP_SPLIT_DEBUGGER === 'enabled';

export const contentful = {
  spaceId: 'owuwz5p532r4',
  accessToken: '4pZvsGZHXiYQADs_WK_ydKardzqrQKNnBziXOb-CtUE',
  host: `https://graphql.contentful.com/content/v1/spaces/owuwz5p532r4`,
};

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

interface TentpoleCtas {
  botoxDaySite: string;
  giftCards: string;
  giftCardsRegistrationInterstitialCTA: string;
  juvedermDaySite: string;
  mothersDayGiftCardSite: string;
}

type TentpoleEnvTypes = 'production' | 'stage';

export const tentpoleConfig: Record<TentpoleEnvTypes, TentpoleCtas> = {
  production: {
    botoxDaySite: 'https://botoxcosmeticday.alle.com/',
    giftCards: 'https://giftcards.alle.com',
    giftCardsRegistrationInterstitialCTA: 'https://giftcards.alle.com/checkout',
    juvedermDaySite: 'https://juvedermday.alle.com/',
    mothersDayGiftCardSite: 'https://mothersday.alle.com/',
  },
  stage: {
    botoxDaySite: 'https://botoxcosmeticday.dev-alle.com/',
    giftCards: 'https://giftcards.staging-brilliantconnections.com/',
    giftCardsRegistrationInterstitialCTA:
      'https://giftcards.staging-brilliantconnections.com/checkout',
    juvedermDaySite: 'https://juvedermday.dev-alle.com/',
    mothersDayGiftCardSite: 'https://mothersday.dev-alle.com/',
  },
};

const tentpoleEnv = process.env.REACT_APP_BOTOX_ENV;
export const currentTentpoleConfig =
  tentpoleEnv === 'production'
    ? tentpoleConfig.production
    : tentpoleConfig.stage;

export const botoxDaySite = currentTentpoleConfig.botoxDaySite;
export const alleGiftCardsLink = currentTentpoleConfig.giftCards;
export const alleGiftCardsRegistrationInterstitialLink =
  currentTentpoleConfig.giftCardsRegistrationInterstitialCTA;
export const juvedermDaySite = currentTentpoleConfig.juvedermDaySite;
export const mothersDayGiftCardSiteLink =
  currentTentpoleConfig.mothersDayGiftCardSite;

export const getLandingPageSubdomainUrl = (slug: string): string => {
  switch (baseUrl) {
    case 'https://alle.com':
      return `https://${slug}.alle.com`;
    case 'https://dev.alle.com':
      return `https://${slug}.dev.alle.com`;
    case 'https://demo.alle.com':
      return `https://${slug}.demo.alle.com`;

    default:
      return `https://${slug}.stage.alle.com`;
  }
};

const splitProxyConfig = {
  production: {
    sdk: 'https://sdk-consumer.features.api.alle.com/api',
    events: 'https://events-consumer.features.api.alle.com/api',
    key: '3q7hb9g1k95jsiln4tfvibku20qimhftmtl5',
  },
  demo: {
    sdk: 'https://sdk-consumer.features.api.demo.alle.com/api',
    events: 'https://events-consumer.features.api.demo.alle.com/api',
    key: 'ncu4jb42l9a0ujf59bodgs3ul20c8j675ge4',
  },
  stage: {
    sdk: 'https://sdk-consumer.features.api.stage.alle.com/api',
    events: 'https://events-consumer.features.api.stage.alle.com/api',
    key: 'dhkei36laqees2luiog8ssddu6p7oq47okin',
  },
  dev: {
    sdk: 'https://sdk-consumer.features.api.dev.alle.com/api',
    events: 'https://events-consumer.features.api.dev.alle.com/api',
    key: 'l4oipuak12390j2qmvt3u7grl1b3sur4h8n2',
  },
};

export const splitProxy =
  process.env.REACT_APP_ALLE_PROXY === 'production'
    ? splitProxyConfig.production
    : process.env.REACT_APP_ALLE_PROXY === 'demo'
    ? splitProxyConfig.demo
    : process.env.REACT_APP_ALLE_PROXY === 'dev'
    ? splitProxyConfig.dev
    : splitProxyConfig.stage;
