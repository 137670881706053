import React from 'react';
import { Document } from '@contentful/rich-text-types';

import { Box } from '@allergan-data-labs/alle-elements-layout';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';

import { RichText } from '@packages/domain-cms/src/components/rich-text/richText';

import { testIds } from '../_testIds';

export type FooterCopyrightProps = {
  copyright?: Document;
  prt?: string;
};

export const fallbackCopyright =
  '© 2025 AbbVie. All rights reserved. Allē and its design are service marks of Allergan, Inc., an AbbVie company. All other trademarks are the property of their respective owners. Allergan Aesthetics reserves the right to alter or cancel the Allē Consumer Loyalty Program with no advance notice or obligation.';

const FooterCopyright = ({ copyright, prt }: FooterCopyrightProps) => {
  const { colorMode } = useColorMode();

  const color =
    colorMode === 'light'
      ? 'dark.Text/Neutral/Support'
      : 'light.Text/Neutral/Support';

  return copyright ? (
    <Box>
      <RichText
        testId={testIds.footerCopyright}
        richText={copyright}
        nodeProps={{
          p: {
            color: color,
            textStyle: 'Body/Small/Regular',
            textAlign: { base: 'center', sm: 'start' },
          },
        }}
      />
      {prt ? (
        <Box
          data-testid={testIds.footerCopyrightPrt}
          as="span"
          color={color}
          textStyle="Body/Small/Regular"
          textAlign={{ base: 'center', sm: 'start' }}
        >
          {prt}
        </Box>
      ) : null}
    </Box>
  ) : (
    <Box
      data-testid={testIds.footerCopyright}
      color={color}
      textStyle="Body/Small/Regular"
      textAlign={{ base: 'center', sm: 'start' }}
    >
      {fallbackCopyright}
    </Box>
  );
};

export { FooterCopyright };
