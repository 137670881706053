import React, { SVGAttributes } from 'react';

export type AlleLogoProps = SVGAttributes<SVGSVGElement>;

const AlleLogo = (props: AlleLogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 26"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4838 25.1965H22.7455V0H25.4838V25.1965ZM0 25.1965H2.67289L4.92198 18.4817H14.5378L16.8196 25.1965H19.6229L11.7346 1.8903H8.0186L0 25.1965ZM9.7457 4.23741L13.7876 16.2325H5.70381L9.7457 4.23741ZM30.2366 25.1965H32.9745V0H30.2366V25.1965ZM51.7933 20.2745C51.2066 23.6972 48.3707 25.4897 44.5243 25.4897C39.7002 25.4897 36.2776 22.0999 36.2776 16.8519V16.5912C36.2776 11.4411 39.5697 7.85533 44.2636 7.85533C48.2076 7.85533 51.9235 10.235 51.9235 16.3305V17.2105H39.0809C39.2111 21.0894 41.069 23.2406 44.5568 23.2406C47.1971 23.2406 48.7293 22.263 49.088 20.2745H51.7933ZM39.1778 15.0268C39.6341 11.9953 41.492 10.1048 44.2625 10.1048C47.0659 10.1048 48.8913 11.5065 49.1519 15.0268H39.1778ZM39.9935 1.98828V4.07436H48.5337V1.98828H39.9935Z"
      fill="currentcolor"
    />
    <path
      d="M52.1469 24.8242H52.3815V24.4073H52.4936L52.7528 24.8242H52.9969L52.7138 24.3679C52.8407 24.3285 52.9336 24.2403 52.9336 24.0783V24.0736C52.9336 23.8527 52.7724 23.7497 52.5233 23.7497H52.1469V24.8242ZM52.3815 24.2453V23.9312H52.5182C52.6353 23.9312 52.704 23.9702 52.704 24.0834V24.0881C52.704 24.1911 52.6404 24.2453 52.5182 24.2453H52.3815ZM52.5182 25.207C52.02 25.207 51.6292 24.8441 51.6292 24.3043C51.6292 23.7692 52.0005 23.3914 52.5182 23.3914C53.0359 23.3914 53.4072 23.7641 53.4072 24.2992C53.4072 24.8343 53.0215 25.207 52.5182 25.207ZM52.5182 25.3838C53.1241 25.3838 53.6028 24.9127 53.6028 24.2992C53.6028 23.6954 53.1339 23.2146 52.5182 23.2146C51.9123 23.2146 51.4336 23.7005 51.4336 24.3043C51.4336 24.9174 51.9173 25.3838 52.5182 25.3838Z"
      fill="currentColor"
    />
  </svg>
);

export { AlleLogo };
