import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import { Box, BoxProps } from '@allergan-data-labs/alle-elements-layout';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@allergan-data-labs/alle-elements-modal';
import { getColorToken } from '@allergan-data-labs/alle-elements-core';

import { logger } from '@packages/core-datadog-logging/src/logger';
import { Maybe } from '@packages/core-alle-graphql-types';

import { AlistPerks } from './alistPerks';
import { AlistTrackerFooter } from './alistTrackerFooter';
import { AlistTrackerSkeletonLoader } from './alistSkeletonLoader';
import { TierAListTypes } from './alistTier/alistTypes';
import { AlistTrackerContent } from './alistTier/alistTrackerContent';
import { TrackerIcon } from './alistTier/alistTrackerIcon';
import { ProgressBar } from './progressBar';

const TrackerHeader = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box
      {...chakraProps}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      padding="24px 0 24px"
      borderRadius="8px 0"
    >
      {children}
    </Box>
  );
};

const ProgressContainer = ({ children, ...chakraProps }: BoxProps) => {
  return (
    <Box {...chakraProps} width="272px" maxWidth="100%" margin="24px 0">
      {children}
    </Box>
  );
};

export interface AlistTrackerProps {
  open: boolean;
  onClose: () => void;
  type: TierAListTypes;
  points: Maybe<number> | undefined;
  tierThreshold?: number | null;
  computedTierPoints: number;
  error?: boolean | undefined;
  loading?: boolean;
}
export const AlistTrackerDialog = (props: AlistTrackerProps) => {
  const isAboveTablet = useBreakpointValue({
    base: false,
    md: true,
  });

  const {
    open,
    onClose,
    type,
    points,
    tierThreshold,
    computedTierPoints,
    loading,
    error,
  } = props;

  const {
    title,
    titleColor,
    message,
    messageColor,
    iconColor,
    theme,
    progressBackground,
    perksTheme,
  } = AlistTrackerContent(type, points, tierThreshold ?? 1200);

  if (error) {
    logger.error('AlistTrackerDetails--Error', {
      error: 'Unable to load Alist Tracker details',
    });
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={open}
      size={isAboveTablet ? 'sm' : 'full'}
      colorMode="dark"
    >
      <ModalOverlay />
      <ModalContent
        data-testid="alist-tracker-modal"
        background={perksTheme}
        width={{ base: '100vw', md: '510px' }}
        borderRadius={{ md: '16px' }}
        zIndex={1401}
      >
        <ModalBody
          padding={0}
          borderRadius="16px"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
              marginTop: '12px',
              marginBottom: '12px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: getColorToken(
                'Text/Neutral/Interactive/Inverted pressed',
                'light'
              ),
              borderRadius: '24px',
            },
          }}
        >
          <TrackerHeader background={theme}>
            <Box
              boxSizing="border-box"
              width="100%"
              display="flex"
              justifyContent="end"
              paddingRight="24px"
            >
              <ModalCloseButton
                data-testid="alist-tracker-close-icon"
                color={iconColor}
              />
            </Box>
            <TrackerIcon type={type} />
            {loading ? (
              <AlistTrackerSkeletonLoader
                backgroundColor={`${progressBackground}`}
              />
            ) : (
              <>
                <ProgressContainer>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="9px"
                  >
                    <ProgressBar
                      value={computedTierPoints}
                      endCapShape="flat"
                      height={3}
                      colorScheme={type === 'MEMBER' ? 'member' : 'a_list'}
                      colorMode="light"
                    />
                    <Box
                      textStyle="Body/Extra small/Medium"
                      textColor={
                        type === 'MEMBER'
                          ? 'light.Text/Brand/Default'
                          : '#C3A45E'
                      }
                    >
                      {computedTierPoints}%
                    </Box>
                  </Box>
                </ProgressContainer>
                <Box
                  data-testid="alist-tracker-title"
                  textStyle="Body/Medium/Medium"
                  textColor={titleColor}
                  width={{ base: '342px', md: '432px' }}
                  maxWidth="100%"
                >
                  {title}
                </Box>
                <Box
                  data-testid="alist-tracker-message"
                  textStyle="Body/Medium/Regular"
                  textColor={messageColor}
                  width={{ base: '342px', md: '432px' }}
                  maxWidth="100%"
                >
                  {message}
                </Box>
              </>
            )}
          </TrackerHeader>
          <AlistPerks isAlister={type !== 'MEMBER'} />
          <AlistTrackerFooter isAlister={type !== 'MEMBER'} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
