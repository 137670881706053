import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

import { Button } from '@allergan-data-labs/alle-elements-button';
import { ChevronDownIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronDownIcon';
import { ChevronUpIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronUpIcon';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';

import { useHeaderColors } from '../../helpers/useHeaderColors';
import { useHeaderTrackingEvents } from '../../helpers/useHeaderTrackingEvents';
import { HeaderLink } from '../../types';
import { HeaderNavLink } from './headerNavLink';
import { testIds } from '../../_testIds';

export interface HeaderNavGroupProps extends HeaderLink {
  isHeaderHidden?: boolean;
}

const HeaderNavGroup = ({
  id,
  title,
  items,
  isHeaderHidden,
}: HeaderNavGroupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const currentPath = history.location.pathname;

  const { colorMode } = useColorMode();

  const { navLinkColor, navLinkHoverColor } = useHeaderColors();
  const { trackHeaderLinkClick } = useHeaderTrackingEvents();

  useEffect(() => {
    if (isHeaderHidden) setIsOpen(false);
  }, [isHeaderHidden]);

  // ensures menu closes if route changes
  useEffect(() => {
    setIsOpen(false);
  }, [currentPath]);

  return (
    <Menu
      key={`${id}-${currentPath}`}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <MenuButton
        data-testid={`${testIds.headerNavLink}-${id}`}
        as={Button}
        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        iconSpacing={2}
        variant="ghost"
        padding={0}
        height="32px"
        color={navLinkColor}
        backgroundColor="transparent"
        fontSize="4"
        sx={{
          _hover: {
            '&:not(.static)': {
              backgroundColor: 'transparent',
              color: `${navLinkHoverColor}`,
            },
          },
          _active: {
            '&:not(.static)': {
              backgroundColor: 'transparent',
              _hover: {
                backgroundColor: 'transparent',
              },
            },
          },
        }}
        onClick={() => {
          setIsOpen(true);
          trackHeaderLinkClick({
            title: title ?? '',
          });
        }}
      >
        {title}
      </MenuButton>
      <MenuList
        maxHeight="unset"
        maxWidth="266px"
        boxSizing="border-box"
        overflowX="hidden"
        overflowY="auto"
        bg={`${colorMode}.Container/Neutral/Base`}
        color={`${colorMode}.Text/Neutral/Default`}
        padding={8}
        border="none"
        boxShadow="Elevation/2"
        borderRadius="8"
        sx={{
          '.chakra-menu__group:not(:first-of-type)': {
            borderTop: '1px',
            borderColor: `${colorMode}.Border/Neutral/Subtle 2`,
            paddingTop: '8',
            marginTop: '8',
          },
          '.action-menu__header + .chakra-menu__group': {
            borderTop: '0',
          },
        }}
      >
        {items?.map((item) => {
          return (
            <MenuItem
              key={item.id}
              border="none"
              padding={0}
              cursor="pointer"
              borderRadius="Small"
              background={`${colorMode}.Container/Neutral/Transparent interactive/Enable`}
              _hover={{
                background: `${colorMode}.Container/Neutral/Transparent interactive/Hover`,
              }}
              _active={{
                background: `${colorMode}.Container/Neutral/Transparent interactive/Pressed`,
              }}
              _focusVisible={{
                background: `${colorMode}.Container/Neutral/Transparent interactive/Hover`,
              }}
            >
              <HeaderNavLink key={item.id} isNested={true} link={item} />
            </MenuItem>
          );
        }) || []}
      </MenuList>
    </Menu>
  );
};

export { HeaderNavGroup };
