import React, { useState, Suspense } from 'react';
import { useHistory } from 'react-router';
import { useBreakpointValue } from '@chakra-ui/react';

import {
  ConsumerProfile,
  LoyaltyPoints,
} from '@packages/core-alle-graphql-types';

import { Box } from '@allergan-data-labs/alle-elements-layout';
import { Avatar } from '@allergan-data-labs/alle-elements-avatar';
import { Button, ButtonGroup } from '@allergan-data-labs/alle-elements-button';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';

import { useAuth } from '../../../../auth/AuthProvider';
import { Routes } from '../../../../constants/routes';
import { AlistTrackerDialog } from '../../../../components/profile/tier/alistTrackerDialog';
import { getAListTierProgressType } from '../../../../components/profile/tier/alistTier/alistHelpers';
import { ConsumerTierAListTypes } from '../../../../components/profile/tier/alistTier/alistTypes';
import { pointsToPercentage } from '../../../../utils/pointsToPercentage';
import { HeaderAccountModal } from './headerAccountModal';
import { useHeaderColors } from '../../helpers/useHeaderColors';
import { useHeaderTrackingEvents } from '../../helpers/useHeaderTrackingEvents';
import { testIds } from '../../_testIds';

export type HeaderAccountProps = {
  isLoggedIn: boolean;
  keepPathOnLogin?: boolean;
};

const HeaderAccount = ({ isLoggedIn, keepPathOnLogin }: HeaderAccountProps) => {
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isAListDialogOpen, setIsAListDialogOpen] = useState(false);

  const history = useHistory();
  const location = history.location;

  const isBelowTablet = useBreakpointValue({
    base: true,
    md: false,
  });

  const { currentUser, consumerProfileLoading, consumerProfileError } =
    useAuth();

  const { Link } = useEnvironmentContext();

  const { avatarBg, avatarForeground, navLinkHoverColor } = useHeaderColors();
  const { trackLoginLinkClick, trackJoinAlleClick, trackProfileAvatarClick } =
    useHeaderTrackingEvents();

  const handleProfileAvatarClick = () => {
    trackProfileAvatarClick();
    setIsAccountModalOpen(!isAccountModalOpen);
  };

  const computedTierPoints = pointsToPercentage(
    currentUser?.loyalty?.points?.tierPoints,
    currentUser?.loyalty?.points?.tierThreshold
  );

  return (
    <Box data-testid={testIds.headerAccount}>
      {!isLoggedIn ? (
        <ButtonGroup alignItems="center" gap={8}>
          <Link
            data-testid={testIds.headerAccountLogin}
            fontSize="14px"
            isStandalone
            variant="action"
            href={Routes.login}
            referrerData={{
              from: {
                pathname: keepPathOnLogin ? location.pathname : '',
                search: location.search ?? '',
              },
            }}
            _hover={{
              textDecoration: 'none',
              color: navLinkHoverColor,
            }}
            onClick={trackLoginLinkClick}
          >
            Log In
          </Link>
          <Link
            data-testid={testIds.headerAccountJoin}
            href={Routes.registrationPhoneNumber}
            onClick={trackJoinAlleClick}
          >
            <Button size="sm" width={95}>
              Join Allē
            </Button>
          </Link>
        </ButtonGroup>
      ) : (
        <Avatar
          data-testid={testIds.headerAccountAvatar}
          name={`${currentUser?.firstName} ${currentUser?.lastName}`}
          borderColor="transparent"
          onClick={handleProfileAvatarClick}
          cursor="pointer"
          // To avoid showing weird initials when user info hasn't loaded
          getInitials={!currentUser ? () => '' : undefined}
          backgroundColor={avatarBg}
          foregroundColor={avatarForeground}
        />
      )}
      <HeaderAccountModal
        profile={currentUser as Partial<ConsumerProfile>}
        isOpen={isAccountModalOpen}
        onClose={() => setIsAccountModalOpen(false)}
        onOpenAListDialog={() => {
          setIsAListDialogOpen(true);
          setTimeout(
            () => {
              setIsAccountModalOpen(false);
            },
            isBelowTablet ? 500 : 0
          );
        }}
      />
      <Suspense fallback={null}>
        <AlistTrackerDialog
          computedTierPoints={computedTierPoints}
          points={currentUser?.loyalty?.points?.tierPoints}
          tierThreshold={currentUser?.loyalty?.points?.tierThreshold}
          type={
            currentUser?.loyalty?.points
              ? getAListTierProgressType(
                  currentUser?.loyalty?.points as LoyaltyPoints
                )
              : ConsumerTierAListTypes.Member
          }
          open={isAListDialogOpen}
          onClose={() => setIsAListDialogOpen(false)}
          error={consumerProfileError !== undefined}
          loading={consumerProfileLoading}
        />
      </Suspense>
    </Box>
  );
};

export { HeaderAccount };
