export const getLocaleTimeStringFromMilliseconds = (
  millisecondsToAdd: number
): string => {
  const now: number = Date.now();
  const timestamp: number = now + millisecondsToAdd;
  const date: Date = new Date(timestamp);

  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};
