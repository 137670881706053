import update from 'immutability-helper';
import { useCallback } from 'react';

const PLATFORM_SEARCH_PARAMS_KEY = 'alle-search-params';

const clearAlleSearchParamsExpiredLocalStorage = () => {
  const now = new Date();
  const alleSearchParams = localStorage.getItem(PLATFORM_SEARCH_PARAMS_KEY);

  if (alleSearchParams) {
    try {
      const adParams = JSON.parse(alleSearchParams);

      if (typeof adParams === 'object' && adParams !== null) {
        const keysToRemove = [];

        for (const key in adParams) {
          const item = adParams[key];

          if (item && item.expiry) {
            const expiryDate = new Date(item.expiry);

            if (expiryDate < now) {
              keysToRemove.push(key);
            }
          }
        }

        if (keysToRemove.length) {
          keysToRemove.forEach((key) => {
            delete adParams[key];
          });

          localStorage.setItem(
            PLATFORM_SEARCH_PARAMS_KEY,
            JSON.stringify(adParams)
          );
        }
      }
    } catch (error) {
      console.error(
        "Error processing 'alle-search-params' localStorage:",
        error
      );
    }
  }
};

const useSaveSearchParams = () => {
  const saveSearchParams = useCallback((search: string) => {
    try {
      clearAlleSearchParamsExpiredLocalStorage();

      const urlParams = new URLSearchParams(search) as unknown as Array<
        [string, string]
      >;
      const paramsMap = new Map();

      for (const [key, value] of urlParams) {
        if (!key.startsWith('ff')) {
          const date_received = new Date();
          const date = new Date();
          date.setDate(date.getDate() + 7);
          paramsMap.set(key, { value, date_received, expiry: date });
        }
      }

      if (paramsMap.size) {
        const storagedParams = localStorage.getItem(
          PLATFORM_SEARCH_PARAMS_KEY
        )!;
        const currentSearchParams = storagedParams
          ? JSON.parse(storagedParams)
          : {};

        localStorage.setItem(
          PLATFORM_SEARCH_PARAMS_KEY,
          JSON.stringify({
            ...Object.fromEntries(paramsMap.entries()),
            ...currentSearchParams,
          })
        );
      }
    } catch (e) {
      console.error('segmentEvents#save-searchParams#hook', e);
    }
  }, []);
  return { saveSearchParams };
};

const transformPayload = (payload: any, query_strings: string) => {
  return update(payload, {
    obj: (obj: any) =>
      update(obj || {}, {
        properties: (properties: any) =>
          update(properties || {}, {
            event: (event: any) =>
              update(event || {}, {
                extra_data: (extraDataCurrent: any) =>
                  update(extraDataCurrent || {}, {
                    $merge: { query_strings },
                  }),
              }),
          }),
      }),
    raw: (raw: any) =>
      update(raw || {}, {
        properties: (properties: any) =>
          update(properties || {}, {
            event: (event: any) =>
              update(event || {}, {
                extra_data: (extraDataCurrent: any) =>
                  update(extraDataCurrent || {}, {
                    $merge: { query_strings },
                  }),
              }),
          }),
      }),
  });
};

function platformSearchParamsMiddleware({ payload, next }: any): void {
  const searchParamsData = localStorage.getItem(PLATFORM_SEARCH_PARAMS_KEY);

  if (searchParamsData) {
    payload = transformPayload(payload, JSON.parse(searchParamsData));
  }

  next(payload);
}

export { platformSearchParamsMiddleware, useSaveSearchParams };
