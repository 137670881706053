import { Routes } from '../../constants/routes';
import { HeaderLink } from './types';

const brandLinks = {
  id: 'item-brands',
  title: 'Our Brands',
  href: '#',
  items: [
    {
      id: 'item-brand-botox',
      title: 'BOTOX® Cosmetic (onabotulinumtoxinA)',
      href: '/treatment-guide/botox',
    },
    {
      id: 'item-brand-juvederm',
      title: 'JUVÉDERM® Collection of Fillers',
      href: '/treatment-guide/juvederm',
    },
    {
      id: 'item-brand-coolsculpting',
      title: 'CoolSculpting®',
      href: '/treatment-guide/coolsculpting',
    },
    {
      id: 'item-brand-cooltone',
      title: 'CoolTone®',
      href: '/treatment-guide/cooltone',
    },
    {
      id: 'item-brand-skinmedica',
      title: 'SkinMedica®',
      href: '/treatment-guide/skinmedica',
    },
    {
      id: 'item-brand-diamondglow',
      title: 'DiamondGlow®',
      href: '/treatment-guide/diamondglow',
    },
    {
      id: 'item-brand-latisse',
      title: 'LATISSE® (bimatoprost ophthalmic solution) 0.03%',
      href: '/treatment-guide/latisse',
    },
    {
      id: 'item-brand-kybella',
      title: 'KYBELLA® (deoxycholic acid) injection 10 mg/mL',
      href: '/treatment-guide/kybella',
    },
    {
      id: 'item-brand-natrelle',
      title: 'Natrelle®',
      href: '/treatment-guide/natrelle',
    },
    {
      id: 'item-brand-skinvive',
      title: 'SKINVIVE by JUVÉDERM®',
      href: '/treatment-guide/skinvive',
    },
    {
      id: 'item-brand-view-all',
      title: 'View all',
      href: Routes.ourBrands,
    },
  ],
};

const loggedInLinks: HeaderLink[] = [
  {
    id: 'item-home',
    title: 'Home',
    href: Routes.accountDashboard,
  },
  {
    id: 'item-providers',
    title: 'Providers',
    href: Routes.providerSearch,
  },
  {
    id: 'item-wallet',
    title: 'Wallet',
    href: Routes.accountWallet,
  },
  {
    id: 'item-more',
    title: 'More',
    href: '#',
    items: [
      {
        id: 'item-how-it-works',
        title: 'How Allē Works',
        href: Routes.howAlleWorks,
      },
      {
        id: 'item-payment-plans',
        title: 'Payment Plans',
        href: Routes.paymentPlansLandingPage,
      },
      {
        id: 'item-about',
        title: 'About Allē & FAQ',
        href: Routes.about,
      },
    ],
  },
];

const loggedOutLinks: HeaderLink[] = [
  brandLinks,
  {
    id: 'item-rewards',
    title: 'Rewards',
    href: '#',
    items: [
      {
        id: 'item-how-it-works',
        title: 'How Allē Works',
        href: Routes.howAlleWorks,
      },
      {
        id: 'item-a-list-benefits',
        title: 'A-List Benefits',
        href: '/article/a-list-benefits',
      },
      {
        id: 'item-about',
        title: 'About Allē & FAQ',
        href: Routes.about,
      },
    ],
  },
  {
    id: 'item-payment-plans',
    title: 'Payment Plans',
    href: Routes.paymentPlansLandingPage,
  },
  {
    id: 'item-get-treated',
    title: 'Get Treated',
    href: Routes.providerSearch,
  },
];

export { loggedInLinks, loggedOutLinks };
