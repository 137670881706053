import React, {
  ComponentType,
  ErrorInfo,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { ErrorBoundary as ErrorBoundaryWrapper } from 'react-error-boundary';

type FallbackProps = {
  error: any;
  resetErrorBoundary: (...args: any[]) => void;
};

type ErrorBoundarySharedProps = PropsWithChildren<{
  onError?: (error: Error, info: ErrorInfo) => void;
  onReset?: (
    details:
      | {
          reason: 'imperative-api';
          args: any[];
        }
      | {
          reason: 'keys';
          prev: any[] | undefined;
          next: any[] | undefined;
        }
  ) => void;
  resetKeys?: any[];
}>;
type ErrorBoundaryPropsWithComponent = ErrorBoundarySharedProps & {
  fallback?: never;
  FallbackComponent: ComponentType<FallbackProps>;
  fallbackRender?: never;
};
type ErrorBoundaryPropsWithRender = ErrorBoundarySharedProps & {
  fallback?: never;
  FallbackComponent?: never;
  fallbackRender: (props: FallbackProps) => ReactNode;
};
type ErrorBoundaryPropsWithFallback = ErrorBoundarySharedProps & {
  fallback: ReactNode;
  FallbackComponent?: never;
  fallbackRender?: never;
};

type ErrorBoundaryProps =
  | ErrorBoundaryPropsWithFallback
  | ErrorBoundaryPropsWithComponent
  | ErrorBoundaryPropsWithRender;

const ErrorBoundary = ({ children, ...rest }: ErrorBoundaryProps) => {
  return <ErrorBoundaryWrapper {...rest}>{children}</ErrorBoundaryWrapper>;
};

export { ErrorBoundary };
export type {
  FallbackProps,
  ErrorBoundaryPropsWithComponent,
  ErrorBoundaryPropsWithRender,
  ErrorBoundaryPropsWithFallback,
  ErrorBoundaryProps,
};
