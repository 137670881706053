export const activityEvents = [
  'mousemove',
  'mousedown',
  'keydown',
  'scroll',
  'touchstart',
  'wheel',
];

export const addActivityListeners = (handler: EventListener) => {
  activityEvents.forEach((event) => {
    window.addEventListener(event, handler);
  });
};

export const removeActivityListeners = (handler: EventListener) => {
  activityEvents.forEach((event) => {
    window.removeEventListener(event, handler);
  });
};
