import React from 'react';

import { Box, Flex } from '@allergan-data-labs/alle-elements-layout';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';

import { AlleLogo } from '@packages/core-components/src/components/svgs/AlleLogo';

import { AuthState } from '../../../auth/authMachine';
import { useAuth } from '../../../auth/AuthProvider';
import { useCmsPreview } from '../../../hooks/useCmsPreview';
import { useCmsFooterQuery, FooterCodeID } from '../helpers/useCmsFooterQuery';
import { FooterSocial } from './footerSocial';
import { FooterNav } from './nav/footerNav';
import { FooterCopyright } from './footerCopyright';
import { CmsFooter } from '../types';
import { testIds } from '../_testIds';

export type FooterProps = {
  footer?: CmsFooter;
  prt?: string;
};

const Footer = ({ footer, prt }: FooterProps) => {
  const { data, isLoading } = useCmsFooterQuery({
    codeId: FooterCodeID.defaultConsumerFooter,
    skip: !!footer,
  });
  const footerData = footer ?? data;

  const { currentUser, authState } = useAuth();
  const { cmsLoggedInPreview } = useCmsPreview();
  const isLoggedIn =
    cmsLoggedInPreview ??
    (!!currentUser || authState.matches(AuthState.isLoggedIn));

  const { colorMode } = useColorMode();

  return !isLoading ? (
    <Box as="footer" position="relative" data-testid={testIds.footer}>
      <Box
        // fixes issue with images appended to body
        position="absolute"
        zIndex={1}
        backgroundColor={`${colorMode}.Section bg/Neutral/Inverted`}
        width="100%"
      >
        <Flex
          position="relative"
          direction="column"
          maxWidth={1440}
          margin="auto"
          padding={{ base: '32px 16px', sm: 56 }}
          rowGap={32}
          boxSizing="border-box"
        >
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            gap={32}
          >
            <Box
              data-testid={testIds.footerLogo}
              width="83px"
              color={`${colorMode}.Icon/Neutral/Inverted`}
            >
              <AlleLogo />
            </Box>
            <FooterSocial />
          </Flex>
          <FooterNav isLoggedIn={isLoggedIn} links={footerData?.links} />
          <FooterCopyright copyright={footerData?.copyright} prt={prt} />
        </Flex>
      </Box>
    </Box>
  ) : null;
};

export { Footer };
