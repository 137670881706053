import React, { MouseEvent, ReactNode } from 'react';
import { ButtonLink } from '@packages/core-components/src/components/button-link/buttonLink';
import { scrollIntoView } from '@packages/core-components/src/utils/scrollIntoView';
import { getTypographyToken } from '@allergan-data-labs/alle-elements-core';
import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';
import { CONSUMER_TRACKING_VALUE } from '@packages/core-segment-tracking/src/consumerTrackingValues';
import { useDomainCmsContext } from '../../context/domainCmsContext';
import { CmsLink } from '../cms-link/cmsLink';
import { formatUrlWithOrigin } from '../../utils/formatUrlWithOrigin';

// TODO: update this logic if request for link enhancement is implemented: UC-3754 + PD-5461
const typographyCss = getTypographyToken('Body/Medium/Regular');
const linkStyles = typographyCss
  ? Object.keys(typographyCss).reduce(
      (styles, key) => {
        styles[key] = 'inherit';
        return styles;
      },
      { textColor: 'inherit' } as Record<string, string>
    )
  : { textColor: 'inherit' };

const DefaultRichTextLink = ({
  href,
  onClick,
  children,
}: {
  href: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  children: ReactNode;
}) => {
  const { trackConsumer } = useSegment();
  const { eventTrackingData } = useDomainCmsContext();

  const trackLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    trackConsumer()?.siteNavigated({
      event_source_url: event.currentTarget.baseURI,
      section: eventTrackingData.sectionIdentifier,
      site_type: CONSUMER_TRACKING_VALUE.siteType,
      text: event.currentTarget.innerText,
      url: formatUrlWithOrigin(event.currentTarget.href),
      event_data: {
        action_source: CONSUMER_TRACKING_VALUE.actionSource,
        activator: 'click on text link',
        event_type: 'link engagement',
        user_status: eventTrackingData.userStatus,
      },
    });
  };

  return (
    <CmsLink
      {...linkStyles}
      variant="action"
      href={href}
      onClick={onClick || trackLinkClick}
    >
      {children}
    </CmsLink>
  );
};

const ScrollLink = ({
  url,
  children,
}: {
  url: string;
  children: ReactNode;
}) => {
  const { trackConsumer } = useSegment();
  const { eventTrackingData } = useDomainCmsContext();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    scrollIntoView(url);

    trackConsumer()?.elementEngaged({
      action: 'click on text link',
      event_data: {
        action_source: CONSUMER_TRACKING_VALUE.actionSource,
        activator: 'click on text link',
        explainer: 'scroll link engagement',
        user_status: eventTrackingData.userStatus,
        extra_data: {
          event_source_url: event.currentTarget.baseURI,
          section: eventTrackingData.sectionIdentifier,
          scroll_link: url,
        },
      },
    });
  };

  return (
    <ButtonLink variant="action" {...linkStyles} onClick={handleClick}>
      {children}
    </ButtonLink>
  );
};

export { DefaultRichTextLink, ScrollLink };
