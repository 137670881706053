import { Resolvers } from 'apollo-boost';

import { getEnvConfig } from './getEnvConfig';
import { graphQLProxyUrls } from './application.config';
import {
  AlleApolloClient,
  ApolloClientFactory,
  WriteDataOptions,
} from './client';
import { getAdlSidHeader } from './headers/adl-sid/adlSid';

export interface WebClientConfig {
  name?: string;
  version?: string;
  getAccessToken: () => Promise<string | undefined>;
  resolvers?: Resolvers | Resolvers[];
  initialCache?: WriteDataOptions<any>;
}

const uri = getEnvConfig(graphQLProxyUrls, process.env.REACT_APP_ALLE_PROXY);

const AlleApolloClientWeb = ({
  name,
  version,
  getAccessToken,
  resolvers,
  initialCache,
}: WebClientConfig) => {
  AlleApolloClient(
    {
      name,
      version,
      uri,
      request: async (operation) => {
        const header = getAdlSidHeader();
        const token = await getAccessToken();
        if (token) {
          operation.setContext({
            headers: {
              ...header,
              Authorization: `Bearer ${token}`,
            },
          });
        }
      },
    },
    {
      ...(initialCache ? { initialCache } : {}),
      ...(resolvers ? { resolvers } : {}),
    }
  );
};

// Create a client instance without affecting the global client
const ApolloClientWebFactory = ({
  name,
  version,
  getAccessToken,
}: WebClientConfig) => {
  return ApolloClientFactory({
    name,
    version,
    uri,
    request: async (operation) => {
      const token = await getAccessToken();
      if (token) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    },
  });
};

export { AlleApolloClientWeb, ApolloClientWebFactory };
