export const testIds = {
  header: 'header',
  headerInner: 'header-inner',
  headerMenu: 'header-menu',
  headerLogo: 'header-logo',
  headerNav: 'header-nav',
  headerNavLink: 'header-nav-link',
  headerAccount: 'header-account',
  headerAccountLogin: 'header-account-login',
  headerAccountJoin: 'header-account-join',
  headerAccountAvatar: 'header-account-avatar',
  headerDrawer: 'header-drawer',
  headerDrawerContent: 'header-drawer-content',
  headerDrawerNav: 'header-drawer-nav',
  headerDrawerNavLink: 'header-drawer-nav-link',
  headerDrawerAccountLogin: 'header-drawer-login',
  headerDrawerAccountJoin: 'header-drawer-join',
};
