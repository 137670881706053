import React, { ReactNode } from 'react';

import { Flex, Box } from '@allergan-data-labs/alle-elements-layout';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';

import { FooterNavLink } from './footerNavLink';
import { FooterNavCookies } from './footerNavCookies';
import { FooterNavPrivacy } from './footerNavPrivacy';
import { FooterLink } from '../../types';
import { testIds } from '../../_testIds';

export type FooterNavProps = {
  isLoggedIn?: boolean;
  links?: FooterLink[];
};

export const linkCodeIds = {
  cookieSettingsLink: 'cookieSettingsLink',
  privacyChoicesLink: 'privacyChoicesLink',
} as const;

export type LinkCodeIds = (typeof linkCodeIds)[keyof typeof linkCodeIds];

const StaticCodeIdComponents: Record<LinkCodeIds, ReactNode> = {
  cookieSettingsLink: <FooterNavCookies />,
  privacyChoicesLink: <FooterNavPrivacy />,
};

const FooterNav = ({ isLoggedIn = false, links }: FooterNavProps) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      data-testid={testIds.footerNav}
      gap={6}
      alignItems="center"
      flexWrap="wrap"
      justifyContent={{ base: 'center', sm: 'start' }}
    >
      {links?.map((link, index, array) => {
        let linkComponent;
        if (link.url) linkComponent = <FooterNavLink link={link} />;
        if (link.codeId) {
          linkComponent = StaticCodeIdComponents[link.codeId as LinkCodeIds];
          if (link.codeId === linkCodeIds.cookieSettingsLink && isLoggedIn)
            linkComponent = null;
        }

        const showSpacer = index < array.length - 1;

        return linkComponent ? (
          <Flex key={index} gap={6} alignItems="center">
            {linkComponent}
            {showSpacer ? (
              <Box
                key="index"
                width={2}
                color={`${colorMode}.Text/Neutral/Support`}
              >
                |
              </Box>
            ) : null}
          </Flex>
        ) : null;
      })}
    </Flex>
  );
};

export { FooterNav };
