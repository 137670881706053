import * as yup from 'yup';
import { getLandingPageSubdomainUrl } from '../application.config';

const ALLOWED_SUBDOMAINS = [
  'www',
  'juvederm',
  'botoxcosmetic',
  'giftcards',
  'mothersday',
  'juvedermday',
  'botoxcosmeticday',
  'auth',
];
const ALLOWED_ORIGINS = [
  'https://www.alle.com',
  'https://alle.com',
  'https://dev.alle.com',
  'https://demo.alle.com',
  'https://stage.alle.com',
  'https://giftcards.alle.com',
  'https://giftcards.dev-alle.com',
  'https://mothersday.alle.com',
  'https://mothersday.dev-alle.com',
  'https://juvedermday.alle.com',
  'https://juvedermday.dev-alle.com',
  'https://botoxcosmeticday.alle.com',
  'https://botoxcosmeticday.dev-alle.com',
  'https://www.skinmedica.com',
  'https://www.dev-skinmedica.com',
  'https://justinharpermd.brilliantconnections.com',
  'https://justinharpermd.dev-brilliantconnections.com',
  'https://auth.brilliantconnections.com',
  'https://auth.dev-brilliantconnections.com',
];

export const isRelativeUrl = (url: string): boolean =>
  url.startsWith('/') && !url.startsWith('//');

export const isSameOriginUrl = (url: string): boolean => {
  const parsedUrl = new URL(url);

  return parsedUrl.origin === window.location.origin;
};

export const isOriginAllowed = (url: string): boolean => {
  const parsedUrl = new URL(url);
  const origin = parsedUrl.origin;

  return ALLOWED_ORIGINS.includes(origin);
};

export const isSubDomainAllowed = (url: string): boolean => {
  const parsedUrl = new URL(url);
  const parts = parsedUrl.hostname.split('.');

  if (parts.length >= 3 && parts.length <= 4) {
    const [subDomain] = parts;

    if (!ALLOWED_SUBDOMAINS.includes(subDomain)) {
      return false;
    }

    const expectedUrl = getLandingPageSubdomainUrl(subDomain);

    return parsedUrl.href.startsWith(expectedUrl);
  }

  return false;
};

const URISchema = yup
  .string()
  .required('uri is required')
  .test(
    'is-same-origin-or-relative',
    'uri must be a same-origin URL, a valid subdomain URL, or a relative URL',
    function (url) {
      if (!url) {
        return false;
      }

      if (isRelativeUrl(url)) {
        return true;
      }

      try {
        return (
          isSameOriginUrl(url) ||
          isOriginAllowed(url) ||
          isSubDomainAllowed(url)
        );
      } catch (e) {
        return false;
      }
    }
  );

/**
 * Validates and sanitizes the fromURI parameter to prevent XSS attacks.
 *
 * @param {string} url - The input URI parameter.
 * @returns {string | null} - The sanitized and validated URI.
 * @throws Will throw an error if validation fails.
 */
export const sanitizeUri = (url: string | null | undefined): string | null => {
  if (!url) {
    return null;
  }
  const isValidUri = URISchema.isValidSync(url);

  if (!isValidUri) {
    return null;
  }

  return url;
};
