import React from 'react';

import { useColorMode } from '@allergan-data-labs/alle-elements-core';
import { Box } from '@allergan-data-labs/alle-elements-layout';

import { CmsLink } from '@packages/domain-cms/src/components/cms-link/cmsLink';

import { useFooterTrackingEvents } from '../../helpers/useFooterTrackingEvents';
import { FooterLink } from '../../types';
import { testIds } from '../../_testIds';

export type FooterNavLinkProps = {
  link: FooterLink;
};

const FooterNavLink = ({ link }: FooterNavLinkProps) => {
  const name = link.text?.split(' ').join('-').toLowerCase();

  const { colorMode } = useColorMode();

  const color =
    colorMode === 'light'
      ? 'dark.Text/Neutral/Interactive/Enable'
      : 'light.Text/Neutral/Interactive/Enable';

  const { trackLinkClick } = useFooterTrackingEvents();

  return (
    <CmsLink
      data-testid={`${testIds.footerNavLink}-${name}`}
      href={link.url as string}
      forceOpenSameTab={link.forceOpenSameTab}
      isStandalone={true}
      variant="action"
      colorMode="dark"
      onClick={() => {
        trackLinkClick({ text: link.text, url: link.url });
      }}
    >
      <Box as="span" textStyle="Body/Small/Regular" color={color}>
        {link.text}
      </Box>
    </CmsLink>
  );
};

export { FooterNavLink };
