export const INACTIVITY_TIMER_CONFIG = {
  DEFAULT_LIMIT: 2 * 60 * 60 * 1000, // 2 hours
  DEFAULT_OFFSET: 5 * 60 * 1000, // 5 minutes
  MIN_LIMIT: 10 * 1000, // 10 seconds
  MIN_OFFSET: 5 * 1000, // 5 seconds
} as const;

export interface ValidateInactivityTimerConfigProps {
  limit?: number;
  offset?: number;
}

export const validateInactivityTimerConfig = ({
  limit = INACTIVITY_TIMER_CONFIG.DEFAULT_LIMIT,
  offset = INACTIVITY_TIMER_CONFIG.DEFAULT_OFFSET,
}: ValidateInactivityTimerConfigProps = {}): void => {
  if (limit <= 0) {
    throw new Error('limit must be positive number');
  }

  if (offset <= 0) {
    throw new Error('/^offset must be positive number');
  }

  if (limit < INACTIVITY_TIMER_CONFIG.MIN_LIMIT) {
    throw new Error(
      `limit must be at least ${INACTIVITY_TIMER_CONFIG.MIN_LIMIT}ms`
    );
  }

  if (offset < INACTIVITY_TIMER_CONFIG.MIN_OFFSET) {
    throw new Error(
      `offset must be at least ${INACTIVITY_TIMER_CONFIG.MIN_OFFSET}ms`
    );
  }

  if (limit - offset <= 0) {
    throw new Error('offset must be less than the limit');
  }

  if (limit - offset < INACTIVITY_TIMER_CONFIG.MIN_OFFSET) {
    throw new Error(
      `offset must leave at least ${INACTIVITY_TIMER_CONFIG.MIN_OFFSET}ms before limit`
    );
  }
};
