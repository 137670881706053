import React from 'react';

import { IconButton } from '@allergan-data-labs/alle-elements-button';
import { HamburgerActiveIcon } from '@allergan-data-labs/alle-elements-icons/24/hamburgerActiveIcon';
import { HamburgerInactiveIcon } from '@allergan-data-labs/alle-elements-icons/24/hamburgerInactiveIcon';

import { useHeaderColors } from '../helpers/useHeaderColors';
import { useHeaderTrackingEvents } from '../helpers/useHeaderTrackingEvents';
import { testIds } from '../_testIds';

export type HeaderMenuProps = {
  isOpen: boolean;
  onClick: () => void;
};

const HeaderMenu = ({ isOpen, onClick }: HeaderMenuProps) => {
  const { menuIconColor } = useHeaderColors();
  const { trackHamburgerMenuClick } = useHeaderTrackingEvents();

  const onMenuIconClick = () => {
    trackHamburgerMenuClick?.();
    onClick();
  };

  const MenuIcon = isOpen ? HamburgerActiveIcon : HamburgerInactiveIcon;

  return (
    <IconButton
      onClick={onMenuIconClick}
      variant="ghost"
      data-testid={testIds.headerMenu}
      aria-label="Menu button"
      height="42px"
      minWidth="42px"
      icon={<MenuIcon color={menuIconColor} minWidth="28px" minHeight="28px" />}
    />
  );
};

export { HeaderMenu };
