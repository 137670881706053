import React, { useState } from 'react';
import { Collapse } from '@chakra-ui/react';

import { Flex, Box } from '@allergan-data-labs/alle-elements-layout';
import { ChevronDownIcon } from '@allergan-data-labs/alle-elements-icons/24/chevronDownIcon';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';

import { useHeaderTrackingEvents } from '../../helpers/useHeaderTrackingEvents';
import { HeaderLink } from '../../types';
import { HeaderDrawerNavLink } from './headerDrawerNavLink';
import { testIds } from '../../_testIds';
export interface HeaderDrawerNavGroupProps extends HeaderLink {
  onLinkClick: () => void;
}

const HeaderDrawerNavGroup = ({
  id,
  title,
  items,
  onLinkClick,
}: HeaderDrawerNavGroupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { colorMode } = useColorMode();

  const { trackHeaderLinkClick } = useHeaderTrackingEvents();

  return (
    <Box>
      <Flex
        data-testid={`${testIds.headerDrawerNavLink}-${id}`}
        as="button"
        width="full"
        justifyContent="space-between"
        alignItems="center"
        background="none"
        border="none"
        cursor="pointer"
        paddingY={8}
        paddingX={12}
        color={`${colorMode}.Text/Neutral/Default`}
        textAlign="left"
        textStyle="Body/Small/Regular"
        fontSize={7}
        _hover={{
          color: `${colorMode}.Text/Neutral/Support`,
        }}
        _focusVisible={{
          outline: 'none',
        }}
        _active={{
          color: `${colorMode}.Text/Neutral/Support`,
          fontWeight: 'Normal',
        }}
        onClick={() => {
          setIsOpen(!isOpen);
          trackHeaderLinkClick({
            title: title ?? '',
          });
        }}
      >
        <Box flexGrow={1}>{title}</Box>
        <ChevronDownIcon
          transition="transform 0.2s"
          transform={isOpen ? 'rotate(180deg)' : ''}
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Flex direction="column" gap={8}>
          {items?.map((item) => (
            <HeaderDrawerNavLink
              key={item.id}
              link={item}
              onLinkClick={onLinkClick}
            />
          ))}
        </Flex>
      </Collapse>
    </Box>
  );
};

export { HeaderDrawerNavGroup };
