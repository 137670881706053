import React from 'react';

import { Box } from '@allergan-data-labs/alle-elements-layout';
import { TextButton } from '@allergan-data-labs/alle-elements-button';

import { useFooterTrackingEvents } from '../../helpers/useFooterTrackingEvents';
import { testIds } from '../../_testIds';

const FooterNavCookies = () => {
  const { trackCookiesClick } = useFooterTrackingEvents();

  const handleClick = () => {
    (window as any).OneTrust?.ToggleInfoDisplay();
    trackCookiesClick();
  };

  return (
    <TextButton
      data-testid={testIds.footerNavCookies}
      id="ot-sdk-btn"
      border="unset"
      padding="unset"
      colorMode="dark"
      backgroundColor="transparent"
      cursor="pointer"
      _hover={{ textDecoration: 'underline' }}
      onClick={handleClick}
    >
      <Box
        textStyle="Body/Small/Regular"
        color="dark.Text/Neutral/Interactive/Enable"
      >
        Cookie Settings
      </Box>
    </TextButton>
  );
};

export { FooterNavCookies };
