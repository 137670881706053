import React from 'react';

import { Flex } from '@allergan-data-labs/alle-elements-layout';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';

import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';

import { socialLinks } from '../constants';
import { testIds } from '../_testIds';

const FooterSocial = () => {
  const { Link } = useEnvironmentContext();

  const { colorMode } = useColorMode();

  return (
    <Flex data-testid={testIds.footerSocial} gap={24}>
      {socialLinks.map((link) => (
        <Link
          data-testid={`${testIds.footerSocialLink}-${link.id}`}
          key={link.id}
          href={link.url}
          target="_blank"
          display="flex"
          color={`${colorMode}.Icon/Neutral/Inverted`}
          _hover={{
            color: `${colorMode}.Icon/Neutral/Inverted`,
          }}
        >
          <link.icon width={link.width} height="auto" />
        </Link>
      ))}
    </Flex>
  );
};

export { FooterSocial };
